<template>
  <div>
    <el-row :gutter="20" style="margin: 10px">
      <el-col :span="12" :offset="0">
        <el-card style="padding-bottom: 20px">
          <div style="display: flex">
            <img
              src="@/assets/agent/Alipay_logo.png"
              alt=""
              style="width: 80px; height: 80px"
            />
            <div>
              <p style="font-size: 25px; font-weight: bolder">支付宝</p>
              <p>操作简单，方便快捷</p>
            </div>
          </div>
          <p v-show="alipay !== 0">绑定支付宝账号：{{ alipay }}</p>
          <p
            v-show="alipay !== 0"
            class="checkQrCode"
            @click="checkQrCodeDialog(alipay_pic)"
          >
            查看收款二维码
          </p>
          <el-button
            type="primary"
            v-if="alipay == 0"
            style="float: right"
            @click="openDialog('alipay')"
          >
            立即绑定
          </el-button>
          <el-button v-else style="float: right" @click="changeBind(1)"
            >修改绑定</el-button
          >
        </el-card>
      </el-col>
      <el-col :span="12" :offset="0">
        <el-card style="padding-bottom: 20px">
          <div style="display: flex">
            <img
              src="@/assets/agent/Tether_logo.png"
              alt=""
              style="width: 80px; height: 80px"
            />
            <div>
              <p style="font-size: 25px; font-weight: bolder">USDT</p>
              <p>跨境操作更便捷</p>
            </div>
          </div>
          <p v-show="usdt !== 0">绑定收款地址：{{ usdt }}</p>
          <p
            v-show="usdt !== 0"
            class="checkQrCode"
            @click="checkQrCodeDialog(usdt_pic)"
          >
            查看收款二维码
          </p>
          <el-button
            type="success"
            v-if="usdt == 0"
            style="float: right"
            @click="openDialog('usdt')"
          >
            立即绑定
          </el-button>
          <el-button v-else style="float: right" @click="changeBind(2)"
            >修改绑定</el-button
          >
        </el-card>
      </el-col>
      <el-col :span="12" :offset="0"> &nbsp; </el-col>
    </el-row>
    <!-- 绑定弹窗 -->
    <el-dialog
      :title="text + '收款方式绑定'"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-alert
        v-if="type === 'usdt'"
        title="请核实您的交易网络为Tron(TRC20)，因交易网络不一致发生的资金损失极速平台不承担任何责任"
        type="info"
        show-icon
        :closable="false"
      >
      </el-alert>
      <el-alert
        v-if="type === 'usdt'"
        title="此处仅支持填写 USDT(TRC20)
            地址，其他地址(如：BinanceID、OKX等站内交易)将无法收款"
        type="info"
        show-icon
        :closable="false"
        style="margin-top: 10px"
      >
      </el-alert>
      <el-form>
        <el-form-item
          :label="type === 'usdt' ? 'USDT(TRC20)收款地址' : '支付宝收款账号'"
        >
          <el-input v-model="account"></el-input>
        </el-form-item>
        <el-form-item :label="text + '收款二维码'">
          <br />
          <el-upload
            class="avatar-uploader"
            :before-upload="beforeAvatarUpload"
            :show-file-list="false"
            v-loading="loading"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="bindPays()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看收款二维码 -->
    <el-dialog title="查看收款二维码" :visible.sync="qrcodeDialog" width="30%">
      <img :src="qrcode" alt="" style="width: 100%" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="qrcodeDialog = false">取 消</el-button>
        <el-button type="primary" @click="qrcodeDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { bindPay, getPayBind, uploadImg } from "@/api/api";

export default {
  data() {
    return {
      usdt: "",
      alipay: "",
      usdt_pic: "",
      alipay_pic: "",
      dialogVisible: false,
      type: "",
      text: "",
      account: "",
      imageUrl: "",
      progress: "",
      qrcodeDialog: false,
      qrcode: "",
    };
  },
  methods: {
    changeBind(e) {
      if (e == 1) {
        this.account = this.alipay;
        this.imageUrl = this.alipay_pic;
      } else if (e == 2) {
        this.account = this.usdt;
        this.imageUrl = this.usdt_pic;
      }
      this.dialogVisible = true;
    },
    checkQrCodeDialog(e) {
      this.qrcode = e;
      this.qrcodeDialog = true;
    },
    bindPays() {
      if ((this.account, this.imageUrl)) {
        const loading = this.$loading({
          lock: true,
          text: "正在绑定信息",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        const uid = localStorage.getItem("uid");
        let type;
        if (this.type == "usdt") {
          type = 1;
        } else if (this.type == "alipay") {
          type = 2;
        }
        bindPay(uid, this.account, this.imageUrl, type).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("绑定成功！");
            this.getPayBinds();
            this.dialogVisible = false;
            loading.close();
          } else {
            this.$message.error("绑定失败！" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
    // 处理图片上传前的逻辑，读取文件并转换为Base64
    beforeAvatarUpload(file) {
      const loading = this.$loading({
        lock: true,
        text: "正在上传中" + this.progress + "%",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      // this.loading = true;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Image = reader.result; // 获取Base64格式的图片
        // 调用接口上传图片
        uploadImg(base64Image, {
          onUploadProgress: (progressEvent) => {
            this.progress = Math.floor(progressEvent.progress * 100);
            loading.text = "正在上传中 " + this.progress + "%";
          },
        })
          .then((response) => {
            if (response.data.code === 200) {
              this.imageUrl = response.data.data;
              // this.loading = false;
              loading.close();
            } else {
              this.$message.error(response.data.msg);
              // this.loading = false;
              loading.close();
            }
          })
          .catch((error) => {
            console.error("图片上传失败:", error);
            // this.loading = false;
            loading.close();
          });
      };
      return false; // 阻止默认的上传行为，因为我们手动上传图片
    },
    openDialog(e) {
      this.type = e;
      if (e == "usdt") {
        this.text = "USDT";
      } else if (e == "alipay") {
        this.text = "支付宝";
      }
      this.dialogVisible = true;
    },
    getPayBinds() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载绑定信息",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const uid = localStorage.getItem("uid");
      getPayBind(uid).then((res) => {
        if (res.data.code === 200) {
          this.usdt = res.data.data.usdt;
          this.alipay = res.data.data.alipay;
          this.usdt_pic = res.data.data.usdt_pic;
          this.alipay_pic = res.data.data.alipay_pic;
          loading.close();
        } else {
          this.$message.error("绑定信息获取失败！" + res.data.msg);
          loading.close();
        }
      });
    },
  },
  created() {
    this.getPayBinds();
  },
};
</script>

<style>
.checkQrCode {
  color: #409eff;
  cursor: pointer;
}
</style>
