<template>
  <div style="background-color: white; padding-bottom: 50px">
    <!-- 红包领取 -->
    <div>
      <img
        src="@/assets/activity/new-coupon-banner.jpg"
        alt=""
        class="activityBannerImg"
      />
      <div class="ac-butt">
        <img
          @click="dialogVisible = true"
          src="@/assets/activity/coupon-get-bg-1.gif"
          alt=""
          class="giveBut"
        />
      </div>
    </div>
    <!-- 更多优惠-->
    <div>
      <p style="text-align: center; font-size: 37px; font-weight: 100">
        更多优惠活动
      </p>
      <div class="ac-main">
        <el-row :gutter="20">
          <el-col :xs="24" :lg="12" v-for="item in activitys" :key="item.id">
            <div class="activity-card">
              <img
                :src="item.image_url"
                alt=""
                style="width: 100%; height: 200px"
              />
              <div style="font-weight: 100; padding: 15px">
                <button
                  style="float: right"
                  @click="jumpTo(item.start_time, item.end_time, item.jump_url)"
                >
                  立即购买
                </button>
                <span style="margin: 0; font-size: 20px">{{ item.title }}</span>
                <br />
                <span style="margin: 10px 0; font-size: 15px">{{
                  item.description
                }}</span>
                <br />
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 领取弹窗 -->
    <el-dialog
      title="红包明细"
      :visible.sync="dialogVisible"
      :width="dialogWidth"
      center
    >
      <el-row gutter="10">
        <el-col
          :xs="12"
          :lg="8"
          v-for="item in redBag"
          :key="item.id"
          style="margin: 10px 0"
        >
          <div class="redBag">
            <div>
              <span style="margin: 0; font-size: 20px; font-weight: bolder"
                >￥{{ item.amount }}</span
              >
              <span
                style="
                  float: right;
                  background-color: #ff6666;
                  border-radius: 30px;
                  color: white;
                  padding: 0 8px;
                "
                >{{ item.count }}张</span
              >
            </div>
            <div style="font-size: 12px; margin-top: 10px">
              满{{ item.meet }}元使用
            </div>
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <button type="primary" @click="takeRedpacks()">一键领取</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getActivity, takeRedpack } from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      redBagImg: require("@/assets/activity/new-coupon-bg.png"),
      dialogVisible: false,
      dialogWidth: "",
      redBag: [
        {
          id: 1,
          amount: "10",
          meet: "100",
          count: 1,
        },
        {
          id: 2,
          amount: "30",
          meet: "100",
          count: 1,
        },
        {
          id: 3,
          amount: "50",
          meet: "300",
          count: 1,
        },
        {
          id: 4,
          amount: "100",
          meet: "500",
          count: 1,
        },
        {
          id: 5,
          amount: "200",
          meet: "1000",
          count: 1,
        },
        {
          id: 6,
          amount: "500",
          meet: "3000",
          count: 1,
        },
        {
          id: 7,
          amount: "1000",
          meet: "6000",
          count: 1,
        },
        {
          id: 8,
          amount: "2000",
          meet: "30000",
          count: 1,
        },
        {
          id: 9,
          amount: "5000",
          meet: "50000",
          count: 1,
        },
      ],
      activitys: [],
    };
  },
  methods: {
    takeRedpacks() {
      const uid = localStorage.getItem("uid");
      if (uid) {
        const loading = this.$loading({
          lock: true,
          text: "正在加载",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        takeRedpack(uid, this.redBag).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("领取成功!");
            this.dialogVisible = false;
            loading.close();
          } else {
            this.$message.error("领取失败！" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请先登录！");
        this.$router.push("/login");
      }
    },
    jumpTo(startTime, endTime, url) {
      const time = moment().unix();
      if (endTime === null) {
        endTime = 9999999999;
      }
      if (startTime > time) {
        this.$message.warning("活动尚未开始！敬请期待！");
      } else if (time > endTime) {
        this.$message.warning(
          "活动已于" + this.formatDate(endTime) + "结束！敬请关注其他活动！"
        );
      } else {
        window.open(url);
      }
    },
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getActivitys() {
      getActivity().then((res) => {
        if (res.data.code === 200) {
          this.activitys = res.data.data;
        } else {
          this.$message.error("获取活动失败！");
        }
      });
    },
    receive() {
      const token = localStorage.getItem("token");
      if (token) {
        this.dialogVisible = true;
      } else {
        this.$message.error("请先登录！");
        this.$router.push("/login");
        // setTimeout(() => {}, 300);
      }
    },
    screeneeWidth() {
      if (window.innerWidth < 768) {
        this.dialogWidth = "80%";
      } else {
        this.dialogWidth = "40%";
      }
    },
  },
  created() {
    this.screeneeWidth();
    this.getActivitys();
  },
};
</script>

<style>
.activityBannerImg {
  width: 100%;
}
.redBag {
  background-image: url("@/assets/activity/new-coupon-bg.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  color: #ff6666;
  padding: 10px;
}

.giveBut {
  cursor: pointer;
  width: 11.2rem;
}

.activity-card {
  box-shadow: 0px 1px 6px 0px rgba(65, 106, 123, 0.1);
  /* border-radius: 4px; */
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  transition: 0.3s;
  margin-bottom: 20px;
}

.ac-main {
  margin: 0px 100px;
}

.ac-butt {
  text-align: center;
  margin-top: -95.5px;
}

@media screen and (max-width: 768px) {
  .giveBut {
    width: 7rem;
  }
  .ac-butt {
    margin-top: -62px;
  }

  .ac-main {
    margin: 0px 20px;
  }
}
</style>
