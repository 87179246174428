<template>
  <div class="docMain">
    <el-row :gutter="10">
      <el-col :xs="0" :lg="4" :offset="0">
        <t-affix :offset-top="10" :offset-bottom="20" ref="affix">
          <el-card style="color: #666666; padding: 20px">
            <p style="margin: 0"><a href="#序言">序言</a></p>
            <p style="margin: 0"><a href="#子游戏">子游戏</a></p>
            <details>
              <summary>1 <a href="#基础">基础</a></summary>
              <details>
                <summary><a href="#环境和部署">环境和部署</a></summary>
                <p><a href="#环境">环境</a></p>
                <details>
                  <summary><a href="#部署">部署</a></summary>
                  <p><a href="#服务器运行">服务器运行</a></p>
                  <p><a href="#客户端源码部分">客户端源码部分</a></p>
                </details>
              </details>
              <p><a href="#项目目录结构">项目目录结构</a></p>
            </details>
            <details>
              <summary>2 <a href="#架构">架构</a></summary>
              <ul>
                <li><a href="#项目运行流程">项目运行流程</a></li>
              </ul>
            </details>
            <details>
              <summary>3 <a href="#后端">后端</a></summary>
              <p><a href="#Php部分">Php部分</a></p>
              <details>
                <summary><a href="#Lua部分">Lua部分</a></summary>
                <p><a href="#重启服务器">重启服务器</a></p>
                <p><a href="#Ws接口">Ws接口</a></p>
                <p><a href="#游戏逻辑文件">游戏逻辑文件</a></p>
                <p><a href="#Lua打印">Lua打印</a></p>
              </details>
            </details>
            <details>
              <summary>4 <a href="#附录">附录</a></summary>
              <ul>
                <li><a href="#支持Https">支持Https</a></li>
                <li><a href="#打包Apk">打包Apk</a></li>
              </ul>
            </details>
            <!-- <p style="margin: 0; text-decoration: underline; color: #e6a23c">
              <a href="#hide" style="color: #e6a23c; font-style: italic"
                >内容被隐藏</a
              >
            </p> -->
          </el-card>
        </t-affix>
      </el-col>
      <el-col :xs="24" :lg="20" :offset="0">
        <div style="background-color: #009174">
          <img
            src="@/assets/yonologo.png"
            alt=""
            style="height: 50px; margin: 10px 30px"
          />
        </div>
        <el-card>
          <div>
            <h1 style="text-align: center; font-size: 35px">
              YonoGames海外竖版游戏产品文档
            </h1>
            <h1 id="序言">序言</h1>
            <p>
              YonoGames是一款起源于印度市场的棋牌游戏软件，项目创建于2017年，经过多年深耕，产品不断迭代，代码成熟稳定，YonoGames游戏框架在市面上有着非常多的成功案例，是一款市面上非常优秀的棋牌产品。极速对其进行了深度二次开发和优化，让开发者和团队更加轻松的驾驭自己的游戏。客户端采用Cocos
              Creator引擎全新开发，目前支持Android，iOS，H5，完全实现三端兼容。后端则使用Lua语言和PHP语言完成开发，客户端是由一个大厅和多个子游戏组成。
            </p>
            <img
              src="@/assets/yonodoc/lct.png"
              alt=""
              style="width: 100%; margin-top: 10px"
            />
            <h1 id="子游戏">子游戏</h1>
            <p>YonoGames提供了30+款子游戏</p>
            <!-- <div
              v-for="item in game"
              :key="item.id"
              style="display: flex; margin-top: 10px"
            >
              <img :src="item.img" alt="" style="width: 60px; height: 60px" />
              <div style="margin-left: 10px">
                <h3 style="margin: 0">{{ item.title }}</h3>
                <p>{{ item.info }}</p>
              </div>
            </div> -->
            <el-row>
              <el-col
                :span="12"
                v-for="item in game"
                :key="item.id"
                style="display: flex; margin-top: 10px"
              >
                <img :src="item.img" alt="" style="width: 60px; height: 60px" />
                <div style="margin-left: 10px">
                  <h3 style="margin: 0">{{ item.title }}</h3>
                  <!-- <p>{{ item.info }}</p> -->
                  <p v-if="!item.showFull">{{ truncatedInfo(item) }}</p>
                  <p v-else>{{ item.info }}</p>
                  <el-button
                    v-if="item.info.length > maxLength"
                    @click="toggleContent(item)"
                    type="text"
                    style="color: #fa1414"
                  >
                    {{ item.showFull ? "收起" : "阅读更多" }}
                  </el-button>
                </div>
              </el-col>
            </el-row>
            <h1>1 基础</h1>
            <h3 id="环境与部署">环境与部署</h3>
            <h3 id="环境">环境</h3>
            <p>服务器最低配置4G 双核 硬盘50G以上 推荐配置8G4核 硬盘80G以上</p>
            <!-- <p>服务器选择Linux</p> -->
            <p>由于服务端用的skynet框架，目前skynet不支持Windows平台</p>
            <p>因此只能选择Linux操作系统，建议服务器安装CentOS7操作系统</p>
            <!-- <p>安装宝塔Linux面板</p> -->
            <p>
              进入<a
                href="https://www.bt.cn"
                style="cursor: pointer; text-decoration: underline"
                target="_blank"
                >https://www.bt.cn</a
              >在线免费安装宝塔Linux面板
            </p>
            <!-- <p>
              进入<a href="https://www.bt.cn/new/index.html">宝塔面板下载地址</a
              >，点击立即免费安装
            </p> -->
            <img src="@/assets/yonodoc/1.png" alt="" style="width: 100%" />
            <p>下滑找到Linux在线安装，输入服务器ip以及ssh账号密码</p>
            <img src="@/assets/yonodoc/2.png" alt="" style="width: 100%" />
            <p>Php版本选择7.4，随后等待安装即可。</p>
            <p>安装完成会弹出宝塔面板地址以及面板账号密码，注意保存。</p>
            <img src="@/assets/yonodoc/3.png" alt="" style="width: 100%" />
            <p>复制面板地址在浏览器打开</p>
            <img src="@/assets/yonodoc/4.png" alt="" style="width: 100%" />
            <p>输入账号密码进入面板</p>
            <img src="@/assets/yonodoc/5.png" alt="" style="width: 100%" />
            <p>宝塔安装软件，查看一下是否有缺漏</p>
            <img src="@/assets/yonodoc/6.png" alt="" style="width: 100%" />
            <el-alert
              id="hide"
              title="隐藏内容"
              type="warning"
              description="部分内容需要购买后才可查看"
              show-icon
              :closable="false"
              style="margin-top: 10px; cursor: pointer"
              @click.native="$router.push('/yono')"
            >
            </el-alert>
            <h3 id="部署">部署</h3>
            <h3 id="服务器运行">服务器运行</h3>
            <p>点击“网站”后再点击“添加站点”</p>
            <img src="@/assets/yonodoc/7.png" alt="" style="width: 100%" />
            <p>分别创建api接口，后台系统，客户端的网站。上传对应项目文件。</p>
            <img src="@/assets/yonodoc/8.png" alt="" style="width: 100%" />
            <img src="@/assets/yonodoc/9.png" alt="" style="width: 100%" />
            <p>Indiarummy以及usdt文件需要上传到根目录下data文件夹解压.</p>
            <img src="@/assets/yonodoc/10.png" alt="" style="width: 100%" />
            <p>
              完成以上操作，点击客户端网站可<a href="yono.h5.jisuqipai.online"
                >运行游戏</a
              >
            </p>
            <h3 id="客户端源码部分">客户端源码部分</h3>
            <p>客户端项目打开assets\BalootClient\GlobalVar.js文件</p>
            <p>修改域名以及payV5Url支付接口</p>
            <img src="@/assets/yonodoc/11.png" alt="" style="width: 100%" />
            <img src="@/assets/yonodoc/12.png" alt="" style="width: 100%" />
            <h3 id="项目目录结构">项目目录结构</h3>
            <p>Admin后台系统项目文件，api php接口文件，h5客户端项目文件</p>
            <p>路径/www/wwwroot</p>
            <img src="@/assets/yonodoc/13.png" alt="" style="width: 100%" />
            <p>Lua文件indiarummy，usdt支付文件usdt</p>
            <img src="@/assets/yonodoc/14.png" alt="" style="width: 100%" />
            <h1 id="架构">2 架构</h1>
            <h3 id="项目运行流程">项目运行流程</h3>
            <p>域名修改完成后</p>
            <p>在Cocos creator打开项目，编辑器版本2.3.3</p>
            <img src="@/assets/yonodoc/25.png" alt="" style="width: 100%" />
            <p>然后构建发布到指定文件夹</p>
            <img src="@/assets/yonodoc/26.png" alt="" style="width: 100%" />
            <p>弹出窗口后点击构建</p>
            <p>成功后提示completed和successfully</p>
            <img src="@/assets/yonodoc/27.png" alt="" style="width: 100%" />
            <p>打开文件夹压缩</p>
            <img src="@/assets/yonodoc/28.png" alt="" style="width: 100%" />
            <p>压缩文件上传到客户端文件夹解压</p>
            <img src="@/assets/yonodoc/29.png" alt="" style="width: 100%" />
            <p>
              打开Cocos creator
              项目构建发布到指定文件夹，压缩后上传宝塔客户端文件夹解压
            </p>
            <el-alert
              id="hide"
              title="隐藏内容"
              type="warning"
              description="部分内容需要购买后才可查看"
              show-icon
              :closable="false"
              style="margin-top: 10px; cursor: pointer"
              @click.native="$router.push('/yono')"
            >
            </el-alert>
            <h1 id="后端">3 后端</h1>
            <h3 id="Lua部分">Lua部分</h3>
            <h3 id="重启服务器">重启服务器</h3>
            <p>Lua文件每次修改需要在宝塔面板终端重启服务器才能生效</p>
            <p>输入cd /data/indiarummy回车</p>
            <p>再输入./wstart.sh重启服务器</p>
            <img src="@/assets/yonodoc/18.png" alt="" style="width: 100%" />
            <h3 id="Ws接口">Ws接口</h3>
            <p>127.0.0.1:9951 登录以及注册接口 127.0.0.1:9959</p>
            <p>客户端项目以及游戏逻辑接口</p>
            <p>在/data/indiarummy/global/pdefine_msg.lua可以查找9959消息部分</p>
            <img src="@/assets/yonodoc/15.png" alt="" style="width: 100%" />
            <p>在浏览器中打开开发人员工具</p>
            <p>网络Ws中可以查看9951和9959发送和接收的消息</p>
            <img src="@/assets/yonodoc/19.png" alt="" style="width: 100%" />
            <h3 id="游戏逻辑文件">游戏逻辑文件</h3>
            <p>/data/indiarummy/global/pdefine_game.lua</p>
            <p>AGENT文件名，在路径/data/indiarummy/game下可找到对应文件。</p>
            <img src="@/assets/yonodoc/20.png" alt="" style="width: 100%" />
            <img src="@/assets/yonodoc/21.png" alt="" style="width: 100%" />
            <h3 id="Lua打印">Lua打印</h3>
            <p>Lua文件log信息，路径/data/indiarummy/log</p>
            <img src="@/assets/yonodoc/22.png" alt="" style="width: 100%" />
            <p>
              示例：在master文件夹下servermgr.lua使用LOG_INFO 或
              LOG_DEBUG打印，打印内容将会显示在mater.20241018-0.log文件内
            </p>
            <img src="@/assets/yonodoc/23.png" alt="" style="width: 100%" />
            <img src="@/assets/yonodoc/24.png" alt="" style="width: 100%" />
            <h3 id="Php部分">Php部分</h3>
            <p>Api文件夹app/controller路径下</p>
            <img src="@/assets/yonodoc/16.png" alt="" style="width: 100%" />
            <h1 id="附录">4 附录</h1>
            <h3 id="支持Https">支持Https</h3>
            <p>站点修改添加证书</p>
            <img src="@/assets/yonodoc/17.png" alt="" style="width: 100%" />
            <h3 id="打包Apk">打包apk</h3>
            <p>进入 Build > Generate Signed Bundle / APK</p>
            <p>选择 APK，点击 Next</p>
            <p>
              如果你没有现成的签名密钥，需要创建一个新的签名密钥。填写相应的密钥信息，如密钥别名、密码、证书有效期等。
            </p>
            <p>选择密钥存储路径，并保存。</p>
            <p>确认签名的密钥配置后，点击 Next。</p>
            <p>点击 Finish 开始生成 APK 文件。</p>
            <p>
              生成过程会在底部显示进度。生成完成后，APK 文件会保存在项目的
              app/release 目录下。
            </p>
            <p>
              在生成结束后，系统会提示生成 APK
              的路径。你可以点击路径，直接访问并找到生成的 APK 文件。
            </p>
            <el-alert
              id="hide"
              title="隐藏内容"
              type="warning"
              description="部分内容需要购买后才可查看"
              show-icon
              :closable="false"
              style="margin-top: 10px; cursor: pointer"
              @click.native="$router.push('/yono')"
            >
            </el-alert>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      maxLength: 20, // 设置截取的最大长度
      game: [
        {
          id: 1,
          title: "Rummy",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/yonodoc/game/icon_Rummy.png"),
          showFull: false,
        },
        {
          id: 2,
          title: "Ludo",
          info: "传统棋盘游戏现在可以在线多人游戏了，无论您是在家、办公室还是咖啡馆。与您的朋友一起玩，或者与在线玩家一起玩，都可以。",
          img: require("@/assets/yonodoc/game/icon_Ludo.png"),
          showFull: false,
        },
        {
          id: 3,
          title: "Poker",
          info: "扑克游戏是世界上最受欢迎的纸牌游戏之一。 不仅因为它需要技巧，而且还需要勇气和专注，这就是玩家总是喜欢玩扑克的原因。如果你是真正的扑克玩家，那就全押或回家。",
          img: require("@/assets/yonodoc/game/icon_dezhou.png"),
          showFull: false,
        },
        {
          id: 4,
          title: "Crash",
          info: "Crash 是一款容易获胜的游戏，你需要做的只是在火箭坠毁前兑现。火箭以1.00的高度开始飞行，火箭飞行的高度决定您的奖金。两步赢取金币。",
          img: require("@/assets/yonodoc/game/icon_Crash.png"),
          showFull: false,
        },
        {
          id: 5,
          title: "Andar Bahar",
          info: "Andar Bahar 是一款印度游戏，据说起源于印度南部的卡纳塔克邦。它是一种纯粹的趣味性和机会性游戏。在 21 世纪，Andar Bahar 已成为风靡印度各地的游戏。",
          img: require("@/assets/yonodoc/game/icon_ander-banar.png"),
          showFull: false,
        },
        {
          id: 6,
          title: "7 Up Down",
          info: "7 Up Down骰子游戏在欧美非常流行。 对于印度玩家来说，这款游戏是近几年才出现的，但很快就成为玩家们的最爱。游戏上手非常容易，还可以快速获得奖励，适合新手玩家或想要赚取很多奖励的专业玩家。",
          img: require("@/assets/yonodoc/game/icon_7-Up-down.png"),
          showFull: false,
        },
        {
          id: 7,
          title: "Dragon Tiger",
          info: "龙虎之战是一款快节奏的卡牌游戏 在桌子上。当玩家在桌子上押注龙或虎选项时，游戏开始。根据您押注的是龙还是虎框，如果该特定选项上出现最高牌，您就赢了。",
          img: require("@/assets/yonodoc/game/icon_longhu.png"),
          showFull: false,
        },
        {
          id: 8,
          title: "Jhandi Munda",
          info: "Jhandi Munda 是一款印度棋盘游戏， 6 个六面骰子。据说它起源于印度北部，现在闻名整个印度。这是一个简单直接的游戏，玩起来很有趣，而且很容易理解。",
          img: require("@/assets/yonodoc/game/icon_Jhandi-Munda.png"),
          showFull: false,
        },
        {
          id: 9,
          title: "Wingo Lottery",
          info: "Wingo 彩票是一款著名的彩票游戏，玩家 可以选择任意颜色或数字进行下注。与其他多人游戏不同，它有许多投注区供玩家选择。易于学习是 wingo 彩票相对于其他彩票游戏的另一个优势。只需尝试购买您的第一张彩票并赢大奖。",
          img: require("@/assets/yonodoc/game/icon_wingo-lottery.png"),
          showFull: false,
        },
        {
          id: 10,
          title: "Roulette",
          info: "轮盘是最知名的轮盘游戏之一 曾经存在过。它被认为是一种机会游戏，在网络和实体场所中非常受欢迎，因为游戏相对容易玩，事实上，人们甚至不需要成为经验丰富的玩家就可以加入轮盘游戏，下几注并获胜。",
          img: require("@/assets/yonodoc/game/icon_Roulette.png"),
          showFull: false,
        },
      ],
    };
  },
  methods: {
    truncatedInfo(item) {
      return item.info.length > this.maxLength
        ? item.info.substring(0, this.maxLength) + "..."
        : item.info;
    },
    toggleContent(item) {
      item.showFull = !item.showFull;
    },
  },
  created() {
    const token = localStorage.getItem("token");
    if (!token || token === null) {
      this.$message.success("请先登录");
      this.$router.push("/login");
    }
  },
};
</script>

<style>
p {
  margin: 0;
}

a {
  color: #666666;
  text-decoration: none;
}

.docMain {
  margin: 10px 150px;
}

/* .el-card__body,
.el-main {
  padding: 0px !important;
} */

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.read-more,
.read-less {
  color: #409eff;
  cursor: pointer;
  margin-left: 10px;
}

.read-more:hover,
.read-less:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .docMain {
    margin: 10px 20px;
  }
}
</style>
