<template>
  <div class="vmain" v-loading="loading">
    <h1>课程标题：{{ info.title }}</h1>
    <el-row :gutter="20">
      <el-col :xs="24" :lg="19" :offset="0">
        <!--
        <video
          v-loading="vloading"
          :src="videoSrc"
          controls
          class="video-play"
        ></video>
        -->
        <div id="dplayer-video"></div>
      </el-col>
      <el-col :xs="24" :lg="5" :offset="0">
        <el-card style="display: flex">
          <p>视频选集</p>
          <el-tabs tab-position="right" @tab-click="changeVideo">
            <el-tab-pane
              v-for="item in videos"
              :key="item.id"
              :label="item.title"
              :name="item.videourl"
            ></el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getSchoolVideo, getSchoolVideoInfomation } from "@/api/api";
import DPlayer from "dplayer";
export default {
  data() {
    return {
      id: this.$route.query.id,
      info: [],
      videos: [],
      loading: false,
      vloading: false,
      videoSrc: "",
      dp: null,
    };
  },
  methods: {
    dpvideos() {
      this.dp = new DPlayer({
        container: document.getElementById("dplayer-video"),
        autoplay: true,
        theme: "#cb1414",
        hotkey: true,
        logo: require("@/assets/logo1.png"),
        video: {
          url: this.videoSrc,
        },
      });
    },
    getSchoolVideo() {
      this.loading = true;
      getSchoolVideoInfomation(this.id).then((res) => {
        if (res.data.code === 200) {
          this.info = res.data.data;
          this.loading = false;
        } else {
          this.$message.error("获取视频信息失败!");
          this.loading = false;
        }
      });
    },
    changeVideo(e) {
      this.videoSrc = e.name;
      this.dpvideos();
    },
    getSchoolVideos() {
      this.loading = true;
      const id = this.id;
      if (!id) {
        this.$message.error("未获取到视频信息！请返回重试！");
      }
      getSchoolVideo(id)
        .then((res) => {
          if (res.data.code === 200) {
            this.videos = res.data.data;
            this.videoSrc = res.data.data[0].videourl;
            this.dpvideos();
            this.loading = false;
          } else {
            this.$message.error("视频加载失败！" + res.data.msg);
            this.loading = false;
          }
        })
        .catch((err) => {
          this.$message.error("视频加载失败！" + err.data.msg);
          this.loading = false;
        });
    },
  },
  created() {
    this.getSchoolVideos();
    this.getSchoolVideo();
  },
  mounted() {},
};
</script>

<style>
.vmain {
  margin: 0 150px;
}

.video-play {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.dplayer-logo {
  pointer-events: none;
  position: absolute;
  left: 20px;
  top: 20px;
  max-width: 150px;
  max-height: 50px;
  filter: invert(0); /* 反色 */
}

@media screen and (max-width: 768px) {
  .vmain {
    margin: 0 20px;
  }
  .video-play {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .el-col-xs-24 {
    width: 100% !important;
  }
}
</style>
