<template>
  <div>
    <div class="main">
      <h1>{{ newsDetail.title }}</h1>
      <el-divider></el-divider>
      <div style="text-align: left">
        <p>发布时间：{{ formatDate(newsDetail.createtime) }}</p>
        <p v-html="newsDetail.content"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { getArticleDetails } from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      id: "",
      newsDetail: [],
    };
  },
  methods: {
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getArticleDetail() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载新闻详情",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      getArticleDetails(this.id).then((res) => {
        if (res.data.code === 200) {
          this.newsDetail = res.data.data;
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
  },
  created() {
    this.id = this.$route.params.id;
    if (this.id != null || this.id != "") {
      this.getArticleDetail();
    }
  },
};
</script>

<style>
.main {
  margin: 20px 150px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .main {
    margin: 0px 25px;
  }
}
</style>
