<template>
  <div class="userMain">
    <el-row :gutter="10">
      <el-col :xs="0" :lg="6" :offset="0">
        <el-card>
          <div style="text-align: center">
            <img
              :src="avatar"
              alt=""
              style="border-radius: 100px; height: 150px; width: 150px"
            />
            <p>{{ username }}</p>
            <p v-loading="loading">当前余额:{{ money }} CNY</p>
            <button @click="$router.push('/pay')">USDT充值</button>
            <button
              style="background-color: #cb1414; color: white; margin-top: 10px"
              @click="logOut()"
            >
              退出登录
            </button>
          </div>
          <div style="text-align: center">
            <p
              class="menu"
              :class="{ activeMenu: showMenu === 1 }"
              @click="chooseMenu(1)"
            >
              基本资料
            </p>
            <p
              class="menu"
              :class="{ activeMenu: showMenu === 2 }"
              @click="chooseMenu(2)"
            >
              头像设置
            </p>
            <p
              class="menu"
              :class="{ activeMenu: showMenu === 3 }"
              @click="chooseMenu(3)"
            >
              密码修改
            </p>
            <p
              class="menu"
              :class="{ activeMenu: showMenu === 8 }"
              @click="chooseMenu(8)"
            >
              我的优惠卷
            </p>
            <p
              class="menu"
              :class="{ activeMenu: showMenu === 4 }"
              @click="chooseMenu(4)"
            >
              我的订单
            </p>
            <p
              class="menu"
              :class="{ activeMenu: showMenu === 5 }"
              @click="chooseMenu(5)"
            >
              我的产品
            </p>
            <p
              class="menu"
              :class="{ activeMenu: showMenu === 6 }"
              @click="chooseMenu(6)"
            >
              我的服务
            </p>
            <p
              class="menu"
              :class="{ activeMenu: showMenu === 7 }"
              @click="chooseMenu(7)"
            >
              云服务器
            </p>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :lg="18" :offset="0">
        <el-card>
          <div v-if="showMenu === 1">
            <el-popover
              placement="top-start"
              trigger="hover"
              content="实时汇率来自OKX欧易交易所"
            >
              <p slot="reference" v-loading="loading">
                账户余额:{{ money }} CNY
                <i
                  class="fa fa-info"
                  style="
                    border: 1px solid black;
                    border-radius: 100px;
                    padding: 3px 8px;
                  "
                ></i>
              </p>
            </el-popover>
            <el-row>
              <el-col span="8">
                <p>当前邮箱：{{ username }}</p>
              </el-col>
              <el-col span="4">
                <button @click="changeMailDialog = true">修改邮箱</button>
              </el-col>
            </el-row>
            <el-row>
              <el-col span="8">
                <p>手机号：{{ mobile }}</p>
              </el-col>
              <el-col span="4">
                <button @click="phoneBindDialog = true">绑定手机</button>
              </el-col>
            </el-row>
          </div>
          <div v-if="showMenu === 2">
            <el-row :gutter="20">
              <el-col :xs="24" :lg="12" :offset="0">
                <h1>当前头像：</h1>
                <img
                  :src="avatar"
                  alt=""
                  style="border-radius: 100px; height: 150px; width: 150px"
                />
              </el-col>
              <el-col :xs="24" :lg="12" :offset="0">
                <h1>上传新头像：</h1>
                <el-upload
                  class="avatar-uploader"
                  :before-upload="beforeAvatarUpload"
                  :show-file-list="false"
                >
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-progress
                  type="circle"
                  v-show="upload"
                  :percentage="progress"
                  style="
                    position: absolute;
                    margin-top: -160px;
                    margin-left: 25px;
                    background-color: white;
                  "
                ></el-progress>
                <button
                  v-if="imageUrl"
                  style="margin-top: 20px"
                  @click="setNewAvatars()"
                >
                  确认设置头像
                </button>
              </el-col>
            </el-row>
          </div>
          <div v-if="showMenu === 3">
            <div style="display: flex">
              <i
                class="el-icon-message"
                style="
                  background-color: #659c41;
                  color: white;
                  border-radius: 50px;
                  font-size: 25px;
                  padding: 10px;
                "
              ></i>
              <p>您当前邮箱账号为：{{ username }}</p>
            </div>
            <el-form>
              <!-- <el-form-item label="请输入邮箱:" style="width: 100%">
                <el-input v-model="reset.email">
                  <template slot="suffix">
                    <el-button
                      v-if="countdown === 0"
                      type="text"
                      style="color: #cb1414"
                      @click="getMailCaptchas()"
                      >发送验证码</el-button
                    >
                    <el-button
                      v-if="countdown != 0"
                      type="text"
                      style="color: #cb1414; cursor: not-allowed"
                      >{{ countdown }}后可再次发送</el-button
                    >
                  </template>
                </el-input>
              </el-form-item> -->
              <el-form-item label="请输入验证码:" style="width: 100%">
                <!-- <el-input v-model="reset.pwd"></el-input> -->
                <el-input v-model="reset.captcha">
                  <template slot="suffix">
                    <el-button
                      v-if="countdown === 0"
                      type="text"
                      style="color: #cb1414"
                      @click="getMailCaptchas()"
                      >发送验证码</el-button
                    >
                    <el-button
                      v-if="countdown != 0"
                      type="text"
                      style="color: #cb1414; cursor: not-allowed"
                      >{{ countdown }}后可再次发送</el-button
                    >
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="请输入新密码:" style="width: 100%">
                <el-input v-model="reset.pwd"></el-input>
              </el-form-item>
            </el-form>
            <button type="primary" v-if="reset.pwd" @click="resetpwds()">
              确认修改密码
            </button>
          </div>
          <div v-if="showMenu === 4">
            <el-table :data="orderData" style="width: 100%">
              <el-table-column prop="id" label="ID"> </el-table-column>
              <el-table-column prop="ordername" label="订单名称">
              </el-table-column>
              <el-table-column prop="orderno" label="订单号"> </el-table-column>
              <el-table-column prop="createtime" label="创建时间">
              </el-table-column>
              <el-table-column prop="stats" label="状态"> </el-table-column>
            </el-table>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="1"
              style="margin-top: 10px"
            >
            </el-pagination>
          </div>
          <div v-if="showMenu === 5">
            <el-row :gutter="20">
              <el-col :xs="24" :lg="12" :offset="0">
                <el-card>
                  <div style="display: flex">
                    <img
                      src="https://www.freeimg.cn/i/2024/08/12/66b9628c0ea3a.png"
                      style="width: 50px; height: 50px; border-radius: 100px"
                      alt=""
                    />
                    <p>YonoGames海外竖屏版</p>
                  </div>
                  <button
                    @click="$router.push('/yono')"
                    style="float: right; margin-bottom: 20px"
                  >
                    去购买
                  </button>
                </el-card>
              </el-col>
              <el-col :xs="24" :lg="12" :offset="0">
                <el-card>
                  <div style="display: flex">
                    <img
                      src="https://www.freeimg.cn/i/2024/08/12/66b962d764060.png"
                      style="width: 50px; height: 50px; border-radius: 100px"
                      alt=""
                    />
                    <p>CokeJogo出海版</p>
                  </div>
                  <button
                    @click="$router.push('/coke')"
                    style="float: right; margin-bottom: 20px"
                  >
                    去购买
                  </button>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div v-if="showMenu === 6">
            <el-row :gutter="20">
              <el-col :xs="24" :lg="12" :offset="0">
                <el-card>
                  <div style="display: flex">
                    <img
                      src="https://www.freeimg.cn/i/2024/08/13/66ba5359152cf.png"
                      style="width: 50px; height: 50px"
                      alt=""
                    />
                    <p>游戏换皮服务</p>
                  </div>
                  <h1 style="text-align: center">0</h1>
                </el-card>
              </el-col>
              <el-col :xs="24" :lg="12" :offset="0">
                <el-card>
                  <div style="display: flex">
                    <img
                      src="https://www.freeimg.cn/i/2024/08/13/66ba53591c750.png"
                      style="width: 50px; height: 50px"
                      alt=""
                    />
                    <p>二次开发服务</p>
                  </div>
                  <h1 style="text-align: center">0</h1>
                </el-card>
              </el-col>
            </el-row>
            <el-row :gutter="20" style="margin-top: 10px">
              <el-col :xs="24" :lg="12" :offset="0">
                <el-card>
                  <div style="display: flex">
                    <img
                      src="https://www.freeimg.cn/i/2024/08/13/66ba535915189.png"
                      style="width: 50px; height: 50px"
                      alt=""
                    />
                    <p>外包维护包月服务</p>
                  </div>
                  <h1 style="text-align: center">0</h1>
                </el-card>
              </el-col>
              <el-col :xs="24" :lg="12" :offset="0">
                <el-card>
                  <div style="display: flex">
                    <img
                      src="https://www.freeimg.cn/i/2024/08/13/66ba53591cc3f.png"
                      style="width: 50px; height: 50px"
                      alt=""
                    />
                    <p>个性化定制服务</p>
                  </div>
                  <h1 style="text-align: center">0</h1>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div v-if="showMenu === 7">
            <el-row :gutter="20">
              <el-col
                :xs="24"
                :lg="12"
                v-for="item in server"
                :key="item.id"
                style="margin-bottom: 10px"
              >
                <el-card>
                  <div style="display: flex">
                    <img
                      :src="item.image"
                      alt="flag"
                      style="width: 80px; height: 50px"
                    />
                    <span style="margin-left: 10px">{{ item.title }}</span>
                  </div>
                  <h1 style="margin: 0; text-align: center">0</h1>
                  <div style="float: right; margin: 10px 0">
                    <button @click="$router.push('/server/' + item.id)">
                      +创建
                    </button>
                    <button>管理</button>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div v-if="showMenu === 8">
            <!-- {{ activity }} -->
            <el-row gutter="10">
              <el-col
                :xs="12"
                :lg="8"
                v-for="item in activity"
                :key="item.id"
                style="margin: 10px 0"
              >
                <div
                  :class="item.count === 0 ? 'redBag_dark' : 'redBag'"
                  v-loading="acloading"
                >
                  <div>
                    <span
                      style="margin: 0; font-size: 20px; font-weight: bolder"
                      >￥{{ item.min_amount }}</span
                    >
                    <span
                      style="
                        float: right;
                        border-radius: 30px;
                        color: white;
                        padding: 0 8px;
                      "
                      :style="{
                        backgroundColor:
                          item.count === 0 ? '#cccccc' : '#ff6666',
                      }"
                    >
                      {{ item.count }}张</span
                    >
                  </div>
                  <div style="font-size: 12px; margin-top: 10px">
                    满{{ item.pack_amount }}元使用
                  </div>
                  <div style="font-size: 12px; margin-top: 10px; display: flex">
                    <span style="flex: 1" v-if="item.count != 0"
                      >到期时间：{{ formatDate(item.expired_at) }}</span
                    >
                    <span style="flex: 1" v-else>暂无此优惠卷</span>
                    <button
                      @click="$router.push('/')"
                      v-if="item.count != 0"
                      style="font-size: 12px; padding: 5px"
                    >
                      去使用
                    </button>
                    <button
                      v-else
                      style="
                        font-size: 12px;
                        padding: 5px;
                        background-color: #cccccc;
                        cursor: not-allowed;
                      "
                    >
                      去使用
                    </button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div class="phone-menu">
      <i
        class="el-icon-s-unfold"
        @click="drawer = true"
        style="
          font-size: 30px;
          margin-top: 10px;
          background-color: white;
          padding: 15px 15px;
          text-align: center;
          border-radius: 100px;
        "
      ></i>
    </div>
    <!-- 手机抽屉 -->
    <el-drawer :visible.sync="drawer" direction="ltr">
      <div style="text-align: center">
        <img
          :src="avatar"
          alt=""
          style="border-radius: 100px; height: 50px; width: 50px"
        />
        <p>{{ username }}</p>
        <p v-loading="loading">当前余额:{{ amount }}</p>
        <button @click="$router.push('/pay')">USDT充值</button>
      </div>
      <div style="text-align: center">
        <p
          class="menu"
          :class="{ activeMenu: showMenu === 1 }"
          @click="chooseMenu(1)"
        >
          基本资料
        </p>
        <p
          class="menu"
          :class="{ activeMenu: showMenu === 2 }"
          @click="chooseMenu(2)"
        >
          头像设置
        </p>
        <p
          class="menu"
          :class="{ activeMenu: showMenu === 3 }"
          @click="chooseMenu(3)"
        >
          密码修改
        </p>
        <p
          class="menu"
          :class="{ activeMenu: showMenu === 8 }"
          @click="chooseMenu(8)"
        >
          我的优惠卷
        </p>
        <p
          class="menu"
          :class="{ activeMenu: showMenu === 4 }"
          @click="chooseMenu(4)"
        >
          我的订单
        </p>
        <p
          class="menu"
          :class="{ activeMenu: showMenu === 5 }"
          @click="chooseMenu(5)"
        >
          我的产品
        </p>
        <p
          class="menu"
          :class="{ activeMenu: showMenu === 6 }"
          @click="chooseMenu(6)"
        >
          我的服务
        </p>
        <p
          class="menu"
          :class="{ activeMenu: showMenu === 7 }"
          @click="chooseMenu(7)"
        >
          云服务器
        </p>
      </div>
    </el-drawer>
    <!-- 领取优惠卷 -->
    <el-dialog
      title="优惠卷领取提示"
      :visible.sync="getActivityDialog"
      width="30%"
    >
      <p>您还没有领取优惠券，是否想要现在领取红包优惠券？</p>
      <span slot="footer" class="dialog-footer">
        <button @click="getActivityDialog = false">否</button>
        <button type="primary" @click="$router.push('/activity')">是</button>
      </span>
    </el-dialog>
    <!-- 修改邮箱 -->
    <el-dialog title="邮箱修改" :visible.sync="changeMailDialog" width="30%">
      <el-form label-width="100px">
        <el-form-item label="当前账号邮箱">
          {{ username }}
        </el-form-item>
        <el-form-item label="请输入验证码">
          <el-input v-model="cap">
            <template slot="suffix">
              <el-button
                v-if="countdown === 0"
                type="text"
                style="color: #cb1414"
                @click="getMailCaptchas(1)"
                >发送验证码</el-button
              >
              <el-button
                v-if="countdown != 0"
                type="text"
                style="color: #cb1414; cursor: not-allowed"
                >{{ countdown }}后可再次发送</el-button
              >
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="请输入新邮箱">
          <el-input v-model="mail"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button @click="changeMailDialog = false">取 消</button>
        <button type="primary" @click="changeemails()">确 定</button>
      </span>
    </el-dialog>
    <!-- 绑定手机号 -->
    <el-dialog title="绑定手机号" :visible.sync="phoneBindDialog" width="30%">
      <el-form>
        <el-form-item label="请输入手机号">
          <el-input v-model="phone"></el-input>
        </el-form-item>
        <el-form-item label="请输入验证码">
          <el-input v-model="phonecap">
            <template slot="suffix">
              <el-button
                v-if="countdown === 0"
                type="text"
                style="color: #cb1414"
                @click="sends()"
                >发送验证码</el-button
              >
              <el-button
                v-if="countdown != 0"
                type="text"
                style="color: #cb1414; cursor: not-allowed"
                >{{ countdown }}后可再次发送</el-button
              >
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button @click="phoneBindDialog = false">取 消</button>
        <button type="primary" @click="changemobiles()">确 定</button>
      </span>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  changeemail,
  changemobile,
  getActivityForUid,
  getAmount,
  getMailCaptcha,
  getUSDTrate,
  resetpwd,
  send,
  setNewAvatar,
  uploadImg,
} from "@/api/api";
import router from "@/router/router";
import { Loading, Message } from "element-ui";
import moment from "moment";
import { nextTick, ref } from "vue";
const avatar = localStorage.getItem("avatar");
const username = localStorage.getItem("username");
const amount = ref("");
const money = ref("");
const showMenu = ref(1);
const loading = ref(false);
const imageUrl = ref("");
const progress = ref(0);
const upload = ref(false);
const countdown = ref(0);
const drawer = ref(false);
const orderData = ref([]);
const reset = ref({
  email: "",
  pwd: "",
  captcha: "",
});
const server = ref([
  {
    id: 1,
    image: "https://www.freeimg.cn/i/2024/08/12/66b965cb451ef.png",
    title: "印度服务器",
    //跳转链接
    url: "https://www.baidu.com",
  },
  {
    id: 2,
    image: "https://www.freeimg.cn/i/2024/08/12/66b965cb0ed99.jpg",
    title: "印尼服务器",
    //跳转链接
    url: "https://www.baidu.com",
  },
  {
    id: 3,
    image: "https://www.freeimg.cn/i/2024/08/12/66b965cb1874d.png",
    title: "菲律宾服务器",
    //跳转链接
    url: "https://www.baidu.com",
  },
  {
    id: 4,
    image: "https://www.freeimg.cn/i/2024/08/12/66b965cb0c385.png",
    title: "马来西亚服务器",
    //跳转链接
    url: "https://www.baidu.com",
  },
  {
    id: 5,
    image: "https://www.freeimg.cn/i/2024/08/12/66b965cac6b98.png",
    title: "越南服务器",
    //跳转链接
    url: "https://www.baidu.com",
  },
  {
    id: 6,
    image: "https://www.freeimg.cn/i/2024/08/12/66b965cb0b9c7.png",
    title: "巴西服务器",
    //跳转链接
    url: "https://www.baidu.com",
  },
  {
    id: 7,
    image: "https://www.freeimg.cn/i/2024/08/12/66b965cb1e1b5.png",
    title: "美国服务器",
    //跳转链接
    url: "https://www.baidu.com",
  },
]);
const rate = ref(""); // 最新汇率
const activity = ref([]);
const acloading = ref(false);
const getActivityDialog = ref(false);
const mail = ref("");
const cap = ref("");
const changeMailDialog = ref(false);
const phoneBindDialog = ref(false);
const phone = ref("");
const phonecap = ref("");
const mobile = ref("");

// 发送手机验证码
function sends() {
  const event = "changemobile";
  send(phone.value, event).then((res) => {
    if (res.data.code === 1) {
      Message.success("发送成功！");
    } else {
      Message.error("发送失败！" + res.data.msg);
    }
  });
}

// 绑定手机
function changemobiles() {
  const token = localStorage.getItem("token");
  changemobile(token, phone.value, phonecap.value).then((res) => {
    if (res.data.code === 1) {
      Message.success("绑定成功！正在退出登录！");
      this.logout();
    } else {
      Message.error(res.data.msg);
    }
  });
}

// 邮件重置
function changeemails() {
  const token = localStorage.getItem("token");
  changeemail(token, mail.value, cap.value).then((res) => {
    if (res.data.code === 1) {
      Message.success("重置成功！正在退出登录！");
      this.logout();
    } else {
      Message.error(res.data.msg);
    }
  });
}
// 时间格式化
function formatDate(date) {
  return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
}

// 根据UID获取所有优惠卷
function getActivityForUids() {
  acloading.value = true;
  const uid = localStorage.getItem("uid");
  if (!uid) {
    Message.error("用户UID获取失败！");
    acloading.value = false;
  }
  getActivityForUid(uid).then((res) => {
    if (res.data.code === 200) {
      activity.value = res.data.data;
      if (activity.value[0].count <= 0) {
        getActivityDialog.value = true;
      }
      acloading.value = false;
    } else {
      Message.error("获取优惠卷失败！");
      acloading.value = false;
    }
  });
}

// 获取最新汇率
function getUSDTrates() {
  loading.value = true;
  getUSDTrate().then((res) => {
    if (res.data.code === 200) {
      rate.value = res.data.data;
      money.value = amount.value * rate.value;
      loading.value = false;
    } else {
      Message.error(res.data.msg);
      loading.value = false;
    }
  });
}

function chooseMenu(value) {
  showMenu.value = value;
}
function getUserAmount() {
  loading.value = true;
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  getAmount(token, uid)
    .then((res) => {
      if (res.data.code === 200) {
        amount.value = res.data.data;
        getUSDTrates();
      } else {
        Message.error("用户余额获取失败！" + res.data.msg);
        loading.value = false;
      }
    })
    .catch((err) => {
      Message.error("用户余额获取失败！" + err.msg);
      loading.value = false;
    });
}

function beforeAvatarUpload(file) {
  upload.value = true;
  // this.loading = true;
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const base64Image = reader.result; // 获取Base64格式的图片
    // 调用接口上传图片
    uploadImg(base64Image, {
      onUploadProgress: (progressEvent) => {
        progress.value = Math.floor(progressEvent.progress * 100);
        loading.text = "正在上传中 " + this.progress + "%";
      },
    })
      .then((response) => {
        if (response.data.code === 200) {
          imageUrl.value = response.data.data;
          // this.loading = false;
          //   loading.close();
          upload.value = false;
        } else {
          Message.error(response.data.msg);
          // this.loading = false;
          //   loading.close();
          upload.value = false;
        }
      })
      .catch((error) => {
        console.error("图片上传失败:", error);
        // this.loading = false;
        loading.close();
      });
  };
  return false; // 阻止默认的上传行为，因为我们手动上传图片
}

function setNewAvatars() {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  setNewAvatar(token, uid, imageUrl.value)
    .then((res) => {
      if (res.data.code === 200) {
        Message.success("头像设置成功！");
        localStorage.setItem("avatar", imageUrl.value);
        location.reload();
      } else {
        Message.error("头像设置失败！" + res.data.msg);
      }
    })
    .catch((err) => {
      //   Message.error("头像设置失败！" + err.data.msg);
      Message.error("头像设置失败！" + err.data.msg);
    });
}

function getMailCaptchas(value) {
  const mail = localStorage.getItem("username");
  if (mail) {
    let loadingInstance = Loading.service();
    let event;
    if (value == 1) {
      event = "changeemail";
    } else {
      event = "resetpwd";
    }
    getMailCaptcha(mail, event)
      .then((res) => {
        if (res.data.data === 200) {
          Message.success("发送成功！请查看您的邮箱");
          // 启动倒计时，60秒倒计时
          countdown.value = 60;
          this.timer = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--;
            } else {
              clearInterval(this.timer); // 倒计时结束时清除定时器
            }
          }, 1000);
          nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close();
          });
        } else {
          Message.error(res.data.msg);
          nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close();
          });
        }
      })
      .catch((err) => {
        Message.error("发送失败" + err.msg);
        nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
      });
  } else {
    Message.error("请输入邮箱");
  }
}

function resetpwds() {
  let loadingInstance = Loading.service();
  resetpwd(this.reset.email, this.reset.captcha, this.reset.pwd)
    .then((res) => {
      if (res.data.code === 1) {
        Message({
          message: "重置成功，即将推出登录",
          type: "success",
        });
        this.changePwdDialog = false;
        this.logOut();
        nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
      } else {
        Message({
          message: res.data.msg,
          type: "error",
        });
        nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
      }
    })
    .catch((err) => {
      Message({
        message: err.data.msg,
        type: "error",
      });
      this.changePwdDialog = false;
      nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close();
      });
    });
}

function logOut() {
  localStorage.removeItem("username");
  localStorage.removeItem("nickname");
  localStorage.removeItem("mobile");
  localStorage.removeItem("avatar");
  localStorage.removeItem("token");
  localStorage.removeItem("uid");
  localStorage.setItem("isLogin", "false");
  Message.success("退出登录成功！即将刷新页面");
  setTimeout(() => {
    router.replace("/login");
    location.reload();
  }, 1000);
}

function getMobile() {
  const mob = localStorage.getItem("mobile");
  if (mob == null || mob == "") {
    mobile.value = "未绑定";
    console.log("active");
  } else {
    mobile.value = mob;
  }
}
getMobile();
getUserAmount();
getActivityForUids();
</script>

<style>
.userMain {
  margin: 10px 150px;
}

.activeMenu {
  color: #cb1414;
  border-bottom: 0.5px #cb1414 solid !important;
}

.menu {
  border-bottom: 0.5px #909399 solid;
  margin: 20px 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.phone-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  z-index: 99;
  margin-bottom: 100px;
  margin-left: 10px;
  display: none;
}

.redBag {
  background-image: url("@/assets/activity/new-coupon-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  color: #ff6666;
  padding: 10px;
}

.redBag_dark {
  background-image: url("@/assets/activity/redpack_dark.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  color: black;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .userMain {
    margin: 10px 20px;
  }

  .phone-menu {
    display: block;
  }
}
</style>
