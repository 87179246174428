<template>
  <div>
    <el-card style="margin: 10px 150px">
      <h1 style="text-align: center">向极速棋牌进行举报</h1>
      <p>
        近期骗子猖獗，冒充极速棋牌官方的名义到处招摇撞骗，为了保障大家的消费权益，我们提供举报功能。
      </p>
      <p>
        如果您怀疑对方疑似骗子，欢迎向我们进行举报，我们将着手进行调查，同时在此页面也将定期公布骗子飞机号，微信号，QQ号。
      </p>
      <div style="text-align: center">
        <button @click="dialogVisible = true">立刻举报</button>
      </div>
    </el-card>
    <el-card style="margin: 0 150px">
      <h1 style="text-align: center">骗子名单</h1>
      <el-divider>暂无更多</el-divider>
    </el-card>
    <el-dialog title="进行举报" :visible.sync="dialogVisible" width="40%">
      <el-form label-width="100px">
        <el-form-item label="微信号(可选)">
          <el-input v-model="wechat"></el-input>
        </el-form-item>
        <el-form-item label="Telegram账号">
          <el-input v-model="telegram"></el-input>
        </el-form-item>
        <el-form-item label="QQ账号">
          <el-input v-model="qq"></el-input>
        </el-form-item>
        <el-form-item label="举报内容">
          <el-input v-model="content" type="textarea" rows="3"></el-input>
        </el-form-item>
        <el-form-item label="举报附图">
          <el-upload
            class="avatar-uploader"
            :before-upload="beforeAvatarUpload"
            :show-file-list="false"
            v-loading="loading"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button @click="dialogVisible = false">取 消</button>
        <button type="primary" @click="reports()">确 定</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { report, uploadImg } from "@/api/api";
export default {
  data() {
    return {
      dialogVisible: false,
      wechat: "",
      telegram: "",
      qq: "",
      content: "",
      imageUrl: "", // 上传后的图片外链
      loading: false,
      progress: 0,
    };
  },
  methods: {
    // 处理图片上传前的逻辑，读取文件并转换为Base64
    beforeAvatarUpload(file) {
      const loading = this.$loading({
        lock: true,
        text: "正在上传中" + this.progress + "%",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      // this.loading = true;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Image = reader.result; // 获取Base64格式的图片
        // 调用接口上传图片
        uploadImg(base64Image, {
          onUploadProgress: (progressEvent) => {
            this.progress = Math.floor(progressEvent.progress * 100);
            loading.text = "正在上传中 " + this.progress + "%";
          },
        })
          .then((response) => {
            if (response.data.code === 200) {
              this.imageUrl = response.data.data;
              // this.loading = false;
              loading.close();
            } else {
              this.$message.error(response.data.msg);
              // this.loading = false;
              loading.close();
            }
          })
          .catch((error) => {
            console.error("图片上传失败:", error);
            // this.loading = false;
            loading.close();
          });
      };
      return false; // 阻止默认的上传行为，因为我们手动上传图片
    },
    reports() {
      if ((this.telegram, this.wechat, this.qq, this.content)) {
        const loading = this.$loading({
          lock: true,
          text: "正在加载",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        const uid = localStorage.getItem("uid");
        const token = localStorage.getItem("token");
        report(
          token,
          uid,
          this.telegram,
          this.wechat,
          this.qq,
          this.content,
          this.imageUrl
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("举报成功！我们将在稍后进行处理");
            this.dialogVisible = false;
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            // this.dialogVisible = false;
          }
        });
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
  },
  created() {
    const token = localStorage.getItem("token");
    if (!token) {
      this.$router.push("/login");
      this.$message.success("请先登录！");
    }
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
