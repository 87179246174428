<template>
  <div>
    <div class="create_banner">
      <h1 style="margin: 5px; font-size: 40px">YonoGames高端设计研发</h1>
      <h1 style="margin: 5px; font-size: 25px">性价比之王，棋牌出海爆款产品</h1>
    </div>
    <div style="text-align: center; background-color: white">
      <h1 style="margin: 0; padding: 20px 20px 0 20px; font-size: 30px">
        子游戏选择
      </h1>
      <p style="margin: 0; padding: 5px; font-size: 20px; color: #666666">
        完全支持拆分销售，支持单个或多个子游戏自由选购需求
      </p>
      <div class="create_main">
        <el-row :gutter="30">
          <el-col
            :xs="12"
            :lg="4"
            :offset="0"
            style="display: flex; justify-content: center; margin-bottom: 20px"
            v-for="item in game"
            :key="item.id"
          >
            <div
              @click="chooseGame(item.title)"
              :style="{
                border: gameList.includes(item.title) // 检查 gameList 是否包含该游戏
                  ? '1px #ff0000 solid'
                  : '1px #909399 solid',
                borderRadius: '20px',
                width: '300px',
                boxShadow: gameList.includes(item.title)
                  ? '0 0 20px #ff0000'
                  : '0 0 0px #909399',
                transition: 'all 0.3s ease',
              }"
            >
              <img
                :src="item.img"
                alt=""
                style="
                  height: 150px;
                  width: 100%;
                  border-top-left-radius: 20px;
                  border-top-right-radius: 20px;
                "
              />
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  padding: 0 30px 10px 30px;
                "
              >
                <p
                  style="
                    margin: 0;
                    font-size: 15px;
                    margin-top: 5px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100px;
                  "
                >
                  <el-popover
                    placement="top-start"
                    title="游戏视频预览"
                    trigger="hover"
                  >
                    <video
                      :src="item.video"
                      style="width: 300px"
                      autoplay
                      muted
                    ></video>
                    <i class="el-icon-video-play" slot="reference"></i>
                  </el-popover>
                  {{ item.title }}
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <h1 style="margin: 0; padding: 20px">游戏区域</h1>
      <div class="create_main">
        <el-row :gutter="30">
          <el-col
            :xs="24"
            :lg="8"
            :offset="0"
            style="display: flex; justify-content: center; margin-bottom: 20px"
            v-for="item in area"
            :key="item.id"
          >
            <div
              @click="chooseArea(item.name)"
              :style="{
                border:
                  areaName === item.name
                    ? '1px #ff0000 solid'
                    : '1px #909399 solid',
                borderRadius: '20px',
                width: '300px',
                boxShadow:
                  areaName === item.name
                    ? '0 0 20px #ff0000'
                    : '0 0 0px #909399',
                transition: 'all 0.3s ease',
              }"
            >
              <img
                :src="item.img"
                alt=""
                style="
                  height: 180px;
                  width: 100%;
                  border-top-left-radius: 20px;
                  border-top-right-radius: 20px;
                "
              />
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  padding: 0 30px 10px 30px;
                "
              >
                <p style="margin: 0; font-size: 20px; margin-top: 5px">
                  {{ item.name }}
                </p>
                <img :src="item.flag" alt="" style="height: 40px" />
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <h1 style="margin: 0; padding: 20px; margin-top: 40px">游戏语言</h1>
      <div class="create_main">
        <el-row :gutter="30">
          <el-col
            :xs="24"
            :lg="8"
            :offset="0"
            style="display: flex; justify-content: center; margin: 10px 0"
            v-for="item in language"
            :key="item.id"
          >
            <div
              @click="chooseLanguage(item.name)"
              :style="{
                display: 'flex',
                border:
                  gameLanguage === item.name
                    ? '1px #ff0000 solid'
                    : '1px #909399 solid',
                width: '300px',
                justifyContent: 'space-around',
                borderRadius: '10px',
                padding: '10px 0',
                boxShadow:
                  gameLanguage === item.name
                    ? '0 0 20px #ff0000'
                    : '0 0 0px #909399',
                transition: 'all 0.3s ease',
              }"
            >
              <p style="font-size: 25px; line-height: 10px; color: #666666">
                {{ item.name }}
              </p>
              <img :src="item.img" alt="" style="width: 100px; height: 60px" />
            </div>
          </el-col>
        </el-row>
      </div>
      <h1 style="margin: 0; padding: 20px; margin-top: 40px">部署方案</h1>
      <div class="choosePlan">
        <h1
          class="create_server"
          :class="{ selected: selectedOption === 1 }"
          @click="selectOption(1)"
        >
          从现有服务器
        </h1>
        <h1
          class="create_server"
          :class="{ selected: selectedOption === 2 }"
          @click="selectOption(2)"
          style="margin-left: 100px"
        >
          云服务部署方式
        </h1>
      </div>
      <!-- 从现有服务器 -->
      <div v-show="selectedOption === 1" class="server_plan">
        <p style="font-size: 30px; margin: 10px">SSH信息</p>
        <el-row :gutter="20" style="text-align: center">
          <el-col :xs="24" :lg="12" :offset="0">
            <div>
              <span style="font-size: 20px">服务器IP：</span>
              <input
                type="text"
                style="
                  background-color: #dedede;
                  border: 0;
                  border-radius: 3px;
                  height: 30px;
                  transform: translateY(-3px);
                "
              />
            </div>
            <div style="margin-top: 30px">
              <span style="font-size: 20px">端口号：</span>
              <input
                type="text"
                style="
                  background-color: #dedede;
                  border: 0;
                  border-radius: 3px;
                  height: 30px;
                  transform: translateY(-3px);
                  margin-left: 15px;
                "
              />
            </div>
          </el-col>
          <el-col :xs="24" :lg="12" :offset="0">
            <div>
              <span style="font-size: 20px">用户名：</span>
              <input
                type="text"
                style="
                  background-color: #dedede;
                  border: 0;
                  border-radius: 3px;
                  height: 30px;
                  transform: translateY(-3px);
                "
              />
            </div>
            <div style="margin-top: 30px">
              <span style="font-size: 20px">密码：</span>
              <input
                type="text"
                style="
                  background-color: #dedede;
                  border: 0;
                  border-radius: 3px;
                  height: 30px;
                  transform: translateY(-3px);
                  margin-left: 15px;
                "
              />
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 云服务部署方式 -->
      <div v-show="selectedOption === 2" class="cloud_plan">
        <p style="font-size: 30px">选择购买服务时长</p>
        <el-row :gutter="20">
          <el-col
            :xs="24"
            :lg="6"
            :offset="0"
            v-for="item in time"
            :key="item.id"
            style="margin: 10px 0"
          >
            <div
              @click="chooseServiceTime(item.time)"
              :style="{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#ffeae6',
                borderRadius: '10px',
                border:
                  serviceTime === item.time
                    ? '1px #ff0000 solid'
                    : '1px #666666 solid',
                boxShadow:
                  serviceTime === item.time
                    ? '0 0 20px #ff0000'
                    : '0 0 0px #909399',
                transition: 'all 0.3s ease',
              }"
            >
              <i
                class="el-icon-caret-right"
                style="color: #1f62b5; line-height: 85px; font-size: 20px"
              ></i>
              <p style="font-size: 25px; margin-left: 30px">{{ item.time }}</p>

              <div
                v-if="item.discount != '无折扣'"
                style="
                  position: absolute;
                  top: 0;
                  right: 0;
                  width: 0;
                  height: 0;
                  border-top: 50px solid red;
                  border-left: 50px solid transparent;
                  border-radius: 0 10px 0 0;
                "
              >
                <span
                  style="
                    position: absolute;
                    top: -30px;
                    right: -30px;
                    left: -30px;
                    transform: rotate(45deg);
                    color: white;
                    font-size: 14px;
                  "
                  >{{ item.discount }}</span
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div>
        <button
          style="
            font-size: 20px;
            background-color: #1f62b5;
            color: white;
            border: 0;
            padding: 10px 30px;
            border-radius: 10px;
            margin: 20px 0;
            cursor: pointer;
          "
        >
          获取报价
        </button>
      </div>
      <!-- 价格计算 -->
      <!-- <div
        style="
          text-align: left;
          margin: 60px 180px 0 180px;
          padding-bottom: 100px;
        "
      >
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <p style="font-size: 30px">价格计算：</p>
          </el-col>
          <el-col :span="12" :offset="0" style="text-align: right">
            <el-select
              v-model="value"
              placeholder="请选择优惠卷"
              style="margin-top: 30px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <p style="font-size: 20px; color: orangered; font-weight: bolder">
              总计价格：30,000CNY
            </p>
            <p style="font-size: 20px; color: orangered; font-weight: bolder">
              优惠金额：1,000CNY
            </p>
            <p style="font-size: 20px; color: orangered; font-weight: bolder">
              结算价格：29,000CNY
            </p>
            <button
              style="
                font-size: 20px;
                background-color: #1f62b5;
                color: white;
                border: 0;
                padding: 10px 30px;
                border-radius: 10px;
              "
            >
              立即支付
            </button>
          </el-col>
        </el-row>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedOption: 1,
      serverIp: "",
      serverPort: "",
      username: "",
      password: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      area: [
        {
          id: 1,
          img: require("@/assets/created/india.png"),
          name: "印度",
          flag: require("@/assets/created/Flag_of_India.png"),
        },
        {
          id: 2,
          img: require("@/assets/created/brazil.png"),
          name: "巴西",
          flag: require("@/assets/created/Flag_of_Brazil.png"),
        },
        {
          id: 3,
          img: require("@/assets/created/Indonesia.png"),
          name: "印尼",
          flag: require("@/assets/created/Flag_of_Indonesia.png"),
        },
        {
          id: 4,
          img: require("@/assets/created/Malaysia.jpg"),
          name: "马来西亚",
          flag: require("@/assets/created/Flag_of_Malaysia.svg.png"),
        },
        {
          id: 5,
          img: require("@/assets/created/Philippines.jpg"),
          name: "菲律宾",
          flag: require("@/assets/created/Flag_of_the_Philippines.svg.png"),
        },
        {
          id: 6,
          img: require("@/assets/created/newyork.jpg"),
          name: "美国",
          flag: require("@/assets/created/Flag_of_the_United_States.svg.png"),
        },
      ],
      areaName: "",
      gameLanguage: "",
      language: [
        {
          id: 1,
          img: require("@/assets/created/lang_english.png"),
          name: "英语",
        },
        {
          id: 2,
          img: require("@/assets/created/lang_portuguese.png"),
          name: "葡萄牙语",
        },
        {
          id: 3,
          img: require("@/assets/created/lang_india.png"),
          name: "印地语",
        },
      ],
      time: [
        {
          id: 1,
          time: "1个月",
          discount: "无折扣",
        },
        {
          id: 2,
          time: "3个月",
          discount: "9折",
        },
        {
          id: 3,
          time: "半年",
          discount: "8折",
        },
        {
          id: 4,
          time: "1年",
          discount: "6折",
        },
      ],
      serviceTime: "",
      game: [
        {
          id: 1,
          title: "Alibaba",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/Alibaba.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Alibaba.mp4",
          showFull: false,
        },
        {
          id: 2,
          title: "Archer",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/Archer.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Archer.mp4",
          showFull: false,
        },
        {
          id: 3,
          title: "G_Cleopatra",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/G_Cleopatra.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/G_Cleopatra.mp4",
          showFull: false,
        },
        {
          id: 4,
          title: "Lamp Of Aladdin",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/LampOfAladdin.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/LampOfAladdin.mp4",
          showFull: false,
        },
        {
          id: 5,
          title: "Power Of TheKraken",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/PowerOfTheKraken.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/PowerOfTheKraken.mp4",
          showFull: false,
        },
        {
          id: 6,
          title: "Regal Tiger",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/RegalTiger.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/RegalTiger.mp4",
          showFull: false,
        },
        {
          id: 7,
          title: "Sinbad",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/Sinbad.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Sinbad.mp4",
          showFull: false,
        },
        {
          id: 8,
          title: "Sky Garden",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/Skygarden.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/Skygarden.mp4",
          showFull: false,
        },
        {
          id: 10,
          title: "Sphinx",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/Sphinx.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Sphinx.mp4",
          showFull: false,
        },
        {
          id: 11,
          title: "The Legend Of Dragon",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/TheLegendOfDragon.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/TheLegendOfDragon.mp4",
          showFull: false,
        },
        {
          id: 12,
          title: "Xerxes",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/Xerxes.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Xerxes.mp4",
          showFull: false,
        },
        {
          id: 13,
          title: "Ander Bahar",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/11.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/AnderBahar.mp4",
          showFull: false,
        },
        {
          id: 14,
          title: "Crash",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/12.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Crash.mp4",
          showFull: false,
        },
        {
          id: 15,
          title: "Jhandi Munda",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/13.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/JhandiMunda.mp4",
          showFull: false,
        },
        {
          id: 16,
          title: "Horse Racing",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/14.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/HorseRacing.mp4",
          showFull: false,
        },
        {
          id: 17,
          title: "Wingo Lottery",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/15.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/WingoLottery.mp4",
          showFull: false,
        },
        {
          id: 18,
          title: "Fortune Wheel",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/16.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/FortuneWheel.mp4",
          showFull: false,
        },
        {
          id: 19,
          title: "Dragon Tiger",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/17.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/DragonTiger.mp4",
          showFull: false,
        },
        {
          id: 20,
          title: "Roulette",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/18.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Roulette.mp4",
          showFull: false,
        },
        {
          id: 21,
          title: "Baccarat",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/19.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Baccarat.mp4",
          showFull: false,
        },
        {
          id: 22,
          title: "7 Up Down",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/20.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/7UpDown.mp4",
          showFull: false,
        },
        {
          id: 23,
          title: "Aladdin's Blessing",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/21.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/Aladdin'sBlessing.mp4",
          showFull: false,
        },
        {
          id: 24,
          title: "Black Jack",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/255.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/BlackJack.mp4",
          showFull: false,
        },
        {
          id: 26,
          title: "Domin",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/265.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Domin.mp4",
          showFull: false,
        },
        {
          id: 27,
          title: "LUDO",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/269.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/LUDO.mp4",
          showFull: false,
        },
        {
          id: 28,
          title: "UNO",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/287.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/UNO.mp4",
          showFull: false,
        },
        {
          id: 29,
          title: "Teen Patti",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/291.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/TeenPatti.mp4",
          showFull: false,
        },
        {
          id: 30,
          title: "Rummy",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/292.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Rummy.mp4",
          showFull: false,
        },
        {
          id: 31,
          title: "Poker",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/293.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Poker.mp4",
          showFull: false,
        },
      ],
      gameList: [],
    };
  },
  methods: {
    chooseServiceTime(time) {
      this.serviceTime = time;
    },
    chooseLanguage(lang) {
      this.gameLanguage = lang;
    },
    chooseArea(area) {
      this.areaName = area;
    },
    chooseGame(game) {
      const index = this.gameList.indexOf(game);
      if (index === -1) {
        // 如果 game 不在 gameList 中，则添加
        this.gameList.push(game);
      } else {
        // 如果 game 已存在于 gameList 中，则移除
        this.gameList.splice(index, 1);
      }
    },
    selectOption(option) {
      this.selectedOption = option; // 切换选中的元素
    },
  },
  created() {},
};
</script>

<style>
.create_banner {
  background-image: url("@/assets/created/banner.png");
  background-size: cover;
  height: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
}

.create_banner h1 {
  color: white;
  text-shadow: -2px -2px 0 #304978, /* 左上 */ 2px -2px 0 #304978,
    /* 右上 */ -2px 2px 0 #304978, /* 左下 */ 2px 2px 0 #304978; /* 右下 */
}

.create_server {
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 20px;
  transition: all 0.2s ease;
}

.selected {
  background-color: #a8d0e6 !important; /* 选中时的背景颜色 */
}

.create_main {
  margin: 0 150px;
}

.choosePlan {
  display: flex;
  justify-content: center;
}

.server_plan {
  text-align: left;
  margin: 0 180px;
  border: 0.5px #666666 solid;
  border-radius: 20px;
  padding: 50px;
}

.cloud_plan {
  text-align: left;
  margin: 0 180px;
  border: 0.5px #666666 solid;
  border-radius: 20px;
  padding: 50px;
}

@media screen and (max-width: 768px) {
  .create_banner {
    height: 200px !important;
    font-size: 20px !important;
  }

  .create_main {
    margin: 0 0px;
  }

  .choosePlan {
    margin: 0 50px;
  }

  .server_plan {
    margin: 0 20px;
    padding: 20px;
  }

  .cloud_plan {
    margin: 0 20px;
    padding: 20px;
  }
}
/* 
@media screen and (min-width: 1920px) {
  .mainArea {
    margin: 0 350px;
  }
} */
</style>
