<template>
  <div class="docMain">
    <el-row :gutter="10">
      <el-col :xs="0" :lg="4" :offset="0">
        <t-affix :offset-top="20" :offset-bottom="20" ref="affix">
          <el-card>
            <details>
              <summary>基础</summary>
              <details>
                <summary>环境与部署</summary>
                <p style="margin: 0px"><a href="#环境">环境</a></p>
                <details>
                  <summary>部署以及运行</summary>
                  <p style="margin: 0px">
                    <a href="#网站、文件配置">网站、文件配置</a>
                  </p>
                  <p style="margin: 0px"><a href="#后端部分">后端部分</a></p>
                  <p style="margin: 0px">
                    <a href="#客户端源码部分">客户端源码部分</a>
                  </p>
                  <p style="margin: 0px"><a href="#项目结构">项目结构</a></p>
                </details>
              </details>
            </details>
            <details>
              <summary>Php</summary>
              <p style="margin: 0"><a href="#Php接口">Php接口</a></p>
            </details>
            <details>
              <summary>附录</summary>
              <p style="margin: 0px"><a href="#支持Https">支持Https</a></p>
              <p style="margin: 0px"><a href="#打包Apk">打包Apk</a></p>
            </details>
          </el-card>
        </t-affix>
      </el-col>
      <el-col :xs="24" :lg="20" :offset="0">
        <el-card>
          <h1 style="text-align: center">CokeJogo出海版游戏文档</h1>
          <h1>基础</h1>
          <h1>环境与部署</h1>
          <h1 id="环境">环境</h1>
          <p>
            服务器最低配置2G双核，硬盘30G以上。推荐配置4G双核，硬盘50G以上。
          </p>
          <h2>服务器选择Linux</h2>
          <p>
            进入宝塔面板下载地址，点击立即免费安装
            <a
              href="https://www.bt.cn/new/index.html"
              style="color: blue; text-decoration: underline"
              >https://www.bt.cn/new/index.html</a
            >
          </p>
          <img src="@/assets/cokedoc/1.png" alt="" style="width: 100%" />
          <p>下滑找到Linux在线安装，输入服务器ip以及ssh账号密码</p>
          <img src="@/assets/cokedoc/2.png" alt="" style="width: 100%" />
          <p>Php版本选择7.4，随后等待安装即可。</p>
          <p>安装完成会弹出宝塔面板地址以及面板账号密码，注意保存。</p>
          <img src="@/assets/cokedoc/3.png" alt="" style="width: 100%" />
          <p>复制面板地址在浏览器打开</p>
          <img src="@/assets/cokedoc/4.png" alt="" style="width: 100%" />
          <p>输入账号密码进入面板</p>
          <img src="@/assets/cokedoc/5.png" alt="" style="width: 100%" />
          <p>宝塔安装软件，查看一下是否有缺漏</p>
          <img src="@/assets/cokedoc/6.png" alt="" style="width: 100%" />
          <h1>部署以及运行</h1>
          <h1 id="网站、文件配置">网站、文件配置</h1>
          <p>点击“网站”后再点击“添加站点”</p>
          <img src="@/assets/cokedoc/7.png" alt="" style="width: 100%" />
          <p>创建api接口和前端网站。上传对应项目文件。</p>
          <img src="@/assets/cokedoc/8.png" alt="" style="width: 100%" />
          <h1 id="后端部分">后端部分</h1>
          <p>Api文件直接放入api网站文件目录解压</p>
          <img src="@/assets/cokedoc/9.png" alt="" style="width: 100%" />
          <h1 id="客户端源码部分">客户端源码部分</h1>
          <p>vscode打开项目文件新建终端</p>
          <p>输入npm install 安装依赖包</p>
          <img src="@/assets/cokedoc/10.png" alt="" style="width: 100%" />
          <p>安装完成后输入</p>
          <p>npm run dev 运行项目</p>
          <img src="@/assets/cokedoc/11.png" alt="" style="width: 100%" />
          <p>成功效果</p>
          <img src="@/assets/cokedoc/12.png" alt="" style="width: 100%" />
          <p>输入npm run build:dev 打包项目</p>
          <img src="@/assets/cokedoc/13.png" alt="" style="width: 100%" />
          <p>成功后会出现dist文件夹</p>
          <img src="@/assets/cokedoc/14.png" alt="" style="width: 100%" />
          <p>压缩后 打包上传服务器</p>
          <img src="@/assets/cokedoc/15.png" alt="" style="width: 100%" />
          <img src="@/assets/cokedoc/16.png" alt="" style="width: 100%" />
          <p>解压文件</p>
          <img src="@/assets/cokedoc/17.png" alt="" style="width: 100%" />
          <p>进入自己配置的前端网站</p>
          <p>成功运行。</p>
          <img src="@/assets/cokedoc/18.png" alt="" style="width: 100%" />
          <h1 id="项目结构">项目结构</h1>
          <p>配置文件</p>
          <p>PgAgVue\env，.env.development</p>
          <img src="@/assets/cokedoc/19.png" alt="" style="width: 100%" />
          <p>修改api路径</p>
          <img src="@/assets/cokedoc/20.png" alt="" style="width: 100%" />
          <p>路径PgAgVue\src\api下url.ts文件存放api接口路径</p>
          <img src="@/assets/cokedoc/21.png" alt="" style="width: 100%" />
          <p>PgAgVue\src\api\modules下不同模块内创建了Promise对象</p>
          <img src="@/assets/cokedoc/22.png" alt="" style="width: 100%" />
          <p>登录接口示例</p>
          <img src="@/assets/cokedoc/23.png" alt="" style="width: 100%" />
          <h1>Php</h1>
          <h1 id="Php接口">Php接口</h1>
          <p>接口路径</p>
          <p>
            cokejogo.api.jisuqipai.online/CokeJogo/application/api/controller
          </p>
          <img src="@/assets/cokedoc/24.png" alt="" style="width: 100%" />
          <h1>附录</h1>
          <h1 id="支持Https">支持Https</h1>
          <p>站点修改添加证书</p>
          <img src="@/assets/cokedoc/25.png" alt="" style="width: 100%" />
          <h1 id="打包Apk">打包Apk</h1>
          <p>进入 Build > Generate Signed Bundle / APK</p>
          <p>选择 APK，点击 Next</p>
          <p>
            如果你没有现成的签名密钥，需要创建一个新的签名密钥。填写相应的密钥信息，如密钥别名、密码、证书有效期等。
          </p>
          <p>选择密钥存储路径，并保存。</p>
          <p>确认签名的密钥配置后，点击 Next。</p>
          <p>点击 Finish 开始生成 APK 文件。</p>
          <p>
            生成过程会在底部显示进度。生成完成后，APK 文件会保存在项目的
            app/release 目录下。
          </p>
          <p>
            在生成结束后，系统会提示生成 APK
            的路径。你可以点击路径，直接访问并找到生成的 APK 文件。
          </p>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {
    const token = localStorage.getItem("token");
    if (!token || token === null) {
      this.$message.success("请先登录");
      this.$router.push("/login");
    }
  },
};
</script>

<style>
.docMain {
  margin: 10px 150px;
}

@media screen and (max-width: 768px) {
  .docMain {
    margin: 10px 20px;
  }
}
</style>
