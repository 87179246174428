<template>
  <div>
    <div class="bg">
      <el-row>
        <el-col :xs="0" :lg="12">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 600px;
            "
          >
            <img
              src="@/assets/bg.png"
              alt=""
              style="width: 100%; height: 100%"
            />
          </div>
        </el-col>
        <el-col :xs="24" :lg="12">
          <div
            class="loginArea"
            style="
              height: 600px;
              backdrop-filter: blur(10px);
              background-color: rgb(255, 255, 255);
              padding: 130px;
            "
          >
            <div>
              <h1>用户登录</h1>
            </div>
            <input
              type="text"
              placeholder="请输入账号"
              v-model="account"
              style="
                background-color: rgba(255, 255, 255, 0.3);
                border: 0px;
                border-bottom: 0.5px #666666 solid;
                height: 30px;
                width: 100%;
                font-size: 17px;
                font-weight: bold;
                margin-top: 20px;
              "
            />
            <input
              type="password"
              placeholder="请输入密码"
              v-model="password"
              style="
                background-color: rgba(255, 255, 255, 0.3);
                border: 0px;
                border-bottom: 0.5px #666666 solid;
                height: 30px;
                width: 100%;
                font-size: 17px;
                font-weight: bold;
                margin-top: 80px;
              "
            />
            <div
              style="
                background-color: #cb1414;
                font-size: 30px;
                color: white;
                margin-top: 50px;
                text-align: center;
                padding: 10px;
                width: 100%;
              "
              @click="Logins()"
            >
              登录
            </div>
            <el-row style="width: 100%">
              <el-col :xs="24" :lg="12" :offset="0">
                <p style="color: #666666; font-weight: bold">
                  还没有账号？<span
                    style="color: #cb1414; cursor: pointer"
                    @click="$router.push('/reg')"
                    >立即注册</span
                  >
                </p>
              </el-col>
              <el-col :xs="24" :lg="12" :offset="0">
                <p @click="findPwDialogVisible = true" class="findPw">
                  找回密码
                </p>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 找回密码 -->
    <el-dialog
      title="找回密码"
      :visible.sync="findPwDialogVisible"
      width="30%"
      center
      style="border: 1px #cb1414 solid"
    >
      <input
        type="text"
        placeholder="请输入邮箱账号"
        v-model="email"
        class="resetInput"
        style="margin-top: 20px"
      />
      <div style="display: flex; align-items: flex-end">
        <input
          type="text"
          placeholder="请输入验证码"
          v-model="resetCaptcha"
          class="resetInput"
          style="margin-top: 40px"
        />
        <button
          @click="resetPwdMail()"
          style="
            background-color: #cb1414;
            color: white;
            border: 0px;
            font-size: 15px;
            width: 160px;
            padding: 10px 10px;
          "
        >
          {{ countdown > 0 ? `重新发送(${countdown}s)` : "获取验证码" }}
        </button>
      </div>
      <input
        type="password"
        placeholder="请输入您的新密码"
        v-model="newPassword"
        class="resetInput"
        style="margin-top: 40px"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="findPwDialogVisible = false">取 消</el-button>
        <el-button
          style="background-color: #cb1414; color: white"
          @click="resetpwds()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 登录/注册提示 -->
    <el-dialog title="用户提示" :visible.sync="noticeDialog" width="30%" center>
      <p>近期冒充极速的骗子较多</p>
      <p>请务必在极速官方网站/App购买产品，谨防上当受骗。</p>
      <p>如有疑问，请及时与极速官方联系核实身份并举报</p>
      <span slot="footer" class="dialog-footer">
        <button type="primary" @click="jumpLogin()" style="cursor: pointer">
          我已知晓({{ countdown }}s)
        </button>
        <button @click="$router.push('/report')" style="cursor: pointer">
          我要举报
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getMailCaptcha, getUserUid, Login, resetpwd } from "@/api/api";

export default {
  data() {
    return {
      account: "",
      password: "",
      captcha: "",
      findPwDialogVisible: false,
      email: "",
      newPassword: "",
      resetCaptcha: "",
      countdown: 0, // 倒计时秒数
      timer: null, // 保存定时器
      noticeDialog: false,
    };
  },
  methods: {
    jumpLogin() {
      this.$message.success("登录成功！即将刷新页面");
      this.$router.push("/");
      location.reload();
    },
    Logins() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      Login(this.account, this.password).then((res) => {
        if (res.data.code === 200) {
          localStorage.setItem("token", res.data.data.userinfo.token);
          localStorage.setItem("avatar", res.data.data.userinfo.avatar);
          localStorage.setItem("nickname", res.data.data.userinfo.nickname);
          localStorage.setItem("username", res.data.data.userinfo.username);
          localStorage.setItem("mobile", res.data.data.userinfo.mobile);
          // localStorage.setItem("uid", res.data.data.userinfo.uid);
          localStorage.setItem("isLogin", true);
          getUserUid(
            res.data.data.userinfo.token,
            res.data.data.userinfo.username
          ).then((res) => {
            if (res.data.code === 200) {
              // this.$message.success("登录成功！即将刷新页面");
              localStorage.setItem("uid", res.data.data);
              this.noticeDialog = true;
              // 启动倒计时，60秒倒计时
              this.countdown = 10;
              this.timer = setInterval(() => {
                if (this.countdown > 0) {
                  this.countdown--;
                } else {
                  clearInterval(this.timer); // 倒计时结束时清除定时器
                  this.jumpLogin();
                }
              }, 1000);
            } else {
              this.$message.warning("登录成功！但uid获取失败！即将刷新页面");
              setTimeout(() => {
                loading.close();
                this.$router.push("/");
                location.reload();
              }, 3000);
            }
          });
        } else {
          this.$message.error("登录失败!" + res.data.msg);
          loading.close();
        }
      });
    },
    resetPwdMail() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const event = "resetpwd";
      getMailCaptcha(this.email, event)
        .then((res) => {
          if (res.data.data === 200) {
            // 启动倒计时，60秒倒计时
            this.countdown = 60;
            this.timer = setInterval(() => {
              if (this.countdown > 0) {
                this.countdown--;
              } else {
                clearInterval(this.timer); // 倒计时结束时清除定时器
              }
            }, 1000);
            this.$message.success("验证码发送成功！");
            loading.close();
          } else {
            this.$message.error("验证码发送失败！" + res.data.msg);
            loading.close();
          }
        })
        .catch((res) => {
          this.$message.error("验证码发送失败！" + res.data.msg);
        });
    },
    resetpwds() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      resetpwd(this.email, this.newPassword, this.resetCaptcha).then((res) => {
        if (res.data.code === 1) {
          loading.close();
          this.$message.success("重置密码成功！");
          this.findPwDialogVisible = false;
          // setTimeout(() => {
          //   this.$router.push("/login");
          // }, 1000);
        } else {
          loading.close();
          this.$message.error("重置密码失败！" + res.data.msg);
        }
      });
    },
  },
  created() {},
  beforeDestroy() {
    // 销毁组件时清除定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style>
.bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.resetInput {
  background-color: rgba(255, 255, 255, 0.3);
  border: 0px;
  border-bottom: 0.5px #666666 solid;
  height: 30px;
  width: 100%;
  font-size: 17px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.resetInput:focus {
  border-bottom: 1px #cb1414 solid; /* 焦点状态下的边框颜色 */
  outline: none; /* 确保焦点时也没有outline */
}

.findPw {
  color: #cb1414;
  font-weight: bold;
  float: right;
}

@media screen and (max-width: 768px) {
  .loginArea {
    padding: 40px !important;
  }
  .findPw {
    float: left;
  }
}
</style>
