<template>
  <div>
    <div class="aboutBg" style="text-align: center; color: white">
      <h1 class="title">ABOUT US</h1>
      <h1 class="info1">关于我们</h1>
      <h1 class="info2">专业、品质、服务，是我们一贯的追求</h1>
    </div>
    <!-- 介绍部分 -->
    <div class="intro">
      <h1>关于极速棋牌</h1>
      <p style="color: #666666">
        极速棋牌，是一家专注于棋牌游戏产品研究和开发的公司，拥有10年以上棋牌行业经验，目前已经服务过1,000名以上优质棋牌客户，为客户提供极具性价比并且可靠稳定的棋牌游戏产品服务，我们拥有行业内领先的技术团队和游戏模式，能够轻松满足客户各种定制需求，助力客户游戏出海。不论棋牌是有经验的客户还是没有经验的客户，我们都有完整的解决方案，帮助客户降低成本投入并快速上线游戏产品。选择极速，就是选择了稳定和靠谱。未来我们也将持续开发出更多极具性价比的棋牌游戏产品。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
.aboutBg {
  background-image: url("@/assets/aboutbg.png");
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.title {
  margin: 0;
  font-weight: bolder;
  font-size: 40px;
  color: rgba(255, 255, 255, 0.4);
}

.info1 {
  font-size: 40px;
  font-weight: 400;
  margin: 0;
}

.info2 {
  font-size: 20px;
  font-weight: 400;
  margin: 10px;
}

.intro {
  text-align: center;
  padding: 0 150px;
}

@media screen and (max-width: 768px) {
  .aboutBg {
    height: 200px;
  }
  .aboutBg > .title {
    font-size: 20px;
  }
  .info1 {
    font-size: 30px;
  }
  .info2 {
    font-size: 15px;
  }
  .intro {
    padding: 20px;
  }
}
</style>
