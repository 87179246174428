<template>
  <div>
    <div style="display: flex; margin-bottom: 10px">
      <p>客户UID：</p>
      <el-input v-model="user_id" style="width: 150px"></el-input>
      <p>创建时间：</p>
      <el-date-picker
        v-model="create_time"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        @change="handleDateChange"
      >
      </el-date-picker>
      <el-button
        type="primary"
        style="margin-left: 10px"
        @click="getClientInfos()"
        >搜索</el-button
      >
    </div>
    <!-- 数据显示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="uid" label="客户uid"> </el-table-column>
      <el-table-column prop="level" label="客户等级">
        <template slot-scope="scope">
          <span v-if="scope.row.level">直属客户</span>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          <span>{{ formatDate(scope.row.createtime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="email" label="邮箱账号"> </el-table-column>
      <el-table-column prop="successions" label="今日登录次数">
      </el-table-column>
      <el-table-column prop="maxsuccessions" label="当月登录次数">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import moment from "moment";
import { getClientInfo } from "@/api/api";
export default {
  data() {
    return {
      tableData: [],
      loading: false,
      user_id: "",
      create_time: "",
      time: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    handleDateChange(dates) {
      if (dates && dates.length === 2) {
        // 转换为 Unix 时间戳
        const startTimestamp = moment(dates[0]).unix();
        const endTimestamp = moment(dates[1]).unix();

        console.log("开始时间戳:", startTimestamp);
        console.log("结束时间戳:", endTimestamp);
        this.time = [moment(dates[0]).unix(), moment(dates[1]).unix()];
      } else {
        this.time = "";
      }
    },
    getClientInfos() {
      this.loading = true;
      const token = localStorage.getItem("token");
      const uid = localStorage.getItem("uid");
      getClientInfo(token, uid, this.user_id, this.time)
        .then((res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data;
            this.loading = false;
          } else {
            this.$message.error(res.data.msg);
            this.loading = false;
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          this.loading = false;
        });
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  created() {
    this.getClientInfos();
  },
};
</script>

<style></style>
