<template>
  <div>
    <t-loading fullscreen text="加载中..." :loading="loading" />
    <div class="schoolmain">
      <t-tabs
        v-model="value"
        style="margin-top: 10px"
        @change="getSchoolVideoLists()"
      >
        <t-tab-panel
          v-for="item in type"
          :key="item.id"
          :value="item.type"
          :label="item.type"
        >
        </t-tab-panel>
      </t-tabs>
      <div style="padding: 10px; background-color: white">
        <el-row :gutter="20">
          <el-col
            :xs="12"
            :lg="6"
            :offset="0"
            v-for="item in videos"
            :key="item.id"
          >
            <div class="vcard" @click="jumpVideo(item.id)">
              <img :src="item.img" alt="" class="learnImg" />
              <p style="margin: 0 10px">{{ item.title }}</p>
              <div style="margin-left: 10px">
                <p v-for="(tag, index) in item.tag" :key="index" class="tag">
                  {{ tag }}
                </p>
                <p style="color: orangered; font-weight: bolder; margin: 0">
                  <span v-if="item.price == 0">免费</span>
                  <span v-else>{{ item.price }}</span>
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-divider v-if="videos.length <= 0">暂时没有更多啦</el-divider>
      </div>
    </div>
  </div>
</template>

<script>
import { getSchoolType, getSchoolVideoList } from "@/api/api";
export default {
  data() {
    return {
      loading: false,
      value: "推荐",
      type: [],
      videos: [],
    };
  },
  methods: {
    jumpVideo(id) {
      const token = localStorage.getItem("token");
      if (!token) {
        this.$message.error("请先登录!");
        this.$router.push("/login");
      } else {
        this.$router.push(`/video/?id=${id}`);
      }
    },
    getSchoolTypes() {
      this.loading = true;
      getSchoolType().then((res) => {
        if (res.data.code === 200) {
          this.type = res.data.data;
          this.loading = false;
          this.getSchoolVideoLists();
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      });
    },
    getSchoolVideoLists() {
      this.loading = true;
      getSchoolVideoList(this.value).then((res) => {
        if (res.data.code === 200) {
          // 遍历处理返回的视频数据
          this.videos = res.data.data.map((item) => ({
            ...item,
            tag: JSON.parse(item.tag), // 解析 tag 字段
          }));
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.getSchoolTypes();
  },
};
</script>

<style>
.learnImg {
  width: 100%;
  height: 150px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.vcard {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #e4e7ed;
  transition: all 0.2s ease;
  margin-bottom: 10px;
}

.vcard:hover {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
}

.tag {
  background-color: #edeff2;
  display: inline;
  color: #9fa6aa;
  font-size: 10px;
  font-weight: bolder;
  padding: 1px 4px;
  margin: 0px;
  margin-right: 5px;
  border-radius: 3px;
}

.schoolmain {
  margin: 0 150px;
}

@media screen and (max-width: 768px) {
  .schoolmain {
    margin: 0 20px;
  }

  .learnImg {
    height: 100px;
  }

  .vcard {
    font-size: 10px;
  }
}
</style>
