<template>
  <div>
    <el-row :gutter="20" style="margin: 10px 150px">
      <el-col :span="5" :offset="0">
        <t-affix :offset-top="10" :offset-bottom="20" ref="affix">
          <el-card>
            <ul>
              <li @click="jump(1)">客户端</li>
              <li @click="jump(2)">后端（ws服务端+API接口服务+后台）</li>
              <li @click="jump(3)">后端的启动步骤</li>
              <li @click="jump(4)">配置参考</li>
              <li @click="jump(5)">关于宝塔Composer安装依赖</li>
            </ul>
          </el-card>
        </t-affix>
      </el-col>
      <el-col :span="19" :offset="0">
        <el-card>
          <h1 style="text-align: center; font-size: 30px">
            极速YonoGames交付文档
          </h1>
          <p style="color: red">
            感谢选择极速棋牌，我们将为您提供最优质的服务和技术支持，助力您业务顺利开展。
          </p>
          <h3 id="1">1、客户端</h3>
          <!-- <img src="@/assets/deliver/logo.png" alt="" />
          <p>包含：完整客户端依赖组件代码+子游戏代码</p> -->
          <p style="color: blue">
            在配置客户端配置文件和打H5包之前，请确保后端都已经配置完成且没有任何问题。
          </p>
          <p>
            安装<span style="color: red">cocos creator2.3.3</span
            >编辑器，打开项目
          </p>
          <p>（1）配置assets\BalootClient\GlobalVar.js文件：</p>
          <img src="@/assets/deliver/cocos.png" alt="" style="width: 100%" />
          <p>修改域名，修改api请求地址payV5Url。</p>
          <p>
            loginServerAddress是也就是端口9951的ws请求。9951登录成功后会返回9959请求地址
            配置好后运行项目浏览器打开效果：
          </p>
          <img src="@/assets/deliver/game.png" alt="" style="width: 100%" />
          <p>（2）打包web mobile：</p>
          <p style="color: blue">项目--->构建发布</p>
          <p>出现弹窗后点击“构建”，注意发布路径不能带有空格，等待完成。</p>
          <p>完成后运行查看效果。</p>
          <p>
            如果能够正常运行，直接压缩文件，上传至宝塔客户端文件夹解压即可。
          </p>
          <img src="@/assets/deliver/cocos1.png" alt="" style="width: 100%" />
          <h3 id="2">2、后端（ws服务端+API接口服务+后台）</h3>
          <p style="color: red">
            极速官方环境支持推荐：Linux(推荐CentOS7)+Nginx+PHP7.4+MySQL5.7+Redis7以上
            Php站点以及ws服务端相关配置请参考下面叙述提出的 4、配置参考
          </p>
          <!-- <p>面板地址:http://111.173.106.175:8888/c8d6c507</p>
          <p>用户名:zncxndqa</p>
          <p>密码:b4d759ca</p> -->
          <p style="color: blue">
            所有完整后端文件包括数据库都可以在宝塔里面导出
            正式环境防火墙放行端口：8888,888,9951,9959，测试建议放行所有端口或者关闭防火墙
            8888，888为宝塔端口；9951为登录端口，9959为子游戏代理端口
          </p>
          <p>（1）PHP站点文件：</p>
          <img src="@/assets/deliver/php.png" alt="" />
          <p>（2）MYSQL：</p>
          <img src="@/assets/deliver/mysql.png" alt="" />
          <p>Indiarummy_game 游戏数据库</p>
          <p>Indiarummy_adm 后台数据库</p>
          <p style="color: blue">建议使用Navicat导入，查看和编辑</p>
          <p>（3）Redis：</p>
          <img src="@/assets/deliver/redis.png" alt="" />
          <p>测试Redis是否部署完整（测试服务器密码设定的123456）：</p>
          <img src="@/assets/deliver/redisTest.png" alt="" />
          <p>
            如果有大量数据表示redis导入正常，停止redis服务后再导入dump.rdb数据持久化文件，再启动redis服务
          </p>
          <img src="@/assets/deliver/redisTest1.png" alt="" />
          <p>（4）ws服务端</p>
          <p>
            主要提供端口服务：9951,9959，再提供一些内部接口(127.0.0.1)供给php调用，比如上下分这些
          </p>
          <img src="@/assets/deliver/ws.png" alt="" />
          <h3 id="3">3、后端的启动步骤：</h3>
          <p>
            因为装了宝塔后mysql和redis通常重启后都会自动启动，如果没有启动，手动先启动mysql和redis服务，然后再进行下面操作。
          </p>
          <p>（1）先启动Swoole服务</p>
          <img src="@/assets/deliver/swoole.png" alt="" />
          <p>终端：</p>
          <p>
            在此之前先确保start.sh以及stop.sh权限为777，否则可能导致启动失败
            chmod命令提权
          </p>
          <img src="@/assets/deliver/tm.png" alt="" />
          <p>（2）启动ws服务端</p>
          <img src="@/assets/deliver/tm2.png" alt="" />
          <p>
            如果中途没有报错说明启动成功。需要注意的是wstart.sh，game_login，game_master，game_node权限为777，如果不是，请使用chmod提权
          </p>
          <h3 id="4">4、配置参考</h3>
          <p>PHP需要安装的扩展支持，装完PHP环境后需要先安装PHP扩展</p>
          <img src="@/assets/deliver/php1.png" alt="" />
          <img src="@/assets/deliver/php2.png" alt="" />
          <img src="@/assets/deliver/php3.png" alt="" />
          <p>（1）swoole站点配置</p>
          <img src="@/assets/deliver/swoole1.png" alt="" />
          <img src="@/assets/deliver/swoole2.png" alt="" />
          <img src="@/assets/deliver/swoole3.png" alt="" />
          <img src="@/assets/deliver/swoole4.png" alt="" />
          <img src="@/assets/deliver/swoole5.png" alt="" />
          <p>（2）ws服务端配置</p>
          <img src="@/assets/deliver/ws2.png" alt="" />
          <p>
            涉及到MYSQL和REDIS的配置都要改，其中wsconfig.node需要额外注意的是：
          </p>
          <img src="@/assets/deliver/ws3.png" alt="" />
          <p>（3）API接口服务配置</p>
          <img src="@/assets/deliver/api.png" alt="" />
          <p>如果composer没有安装依赖，需要先安装项目composer依赖</p>
          <img src="@/assets/deliver/composer.png" alt="" />
          <img src="@/assets/deliver/env.png" alt="" />
          <p>（4）后台配置</p>
          <img src="@/assets/deliver/config.png" alt="" />
          <!-- <p>如果composer没有安装依赖，项目需要先安装composer依赖</p>
          <img src="@/assets/deliver/composer.png" alt="" />
          <img src="@/assets/deliver/env.png" alt="" /> -->
          <h3 id="5">5、关于宝塔Composer安装依赖</h3>
          <img src="@/assets/deliver/composer1.png" alt="" />
          <img
            src="@/assets/deliver/composer2.png"
            alt=""
            style="width: 100%"
          />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    jump(e) {
      window.location.hash = "#" + e; // 修改 URL 的锚点
    },
  },
  created() {
    const token = localStorage.getItem("token");
    if (!token) {
      this.$message.error("请先登录!");
      this.$router.push({ path: "/login" });
    }
  },
};
</script>

<style></style>
