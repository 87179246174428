<template>
  <div>
    <div style="padding: 0; box-sizing: border-box">
      <el-carousel
        v-loading="bannerLoading"
        :height="swipeHeight"
        class="banner_swipe"
      >
        <el-carousel-item v-for="item in swipe" :key="item.id">
          <img
            :src="item.image_url"
            alt=""
            style="background-size: cover; height: 100%; width: 100%"
          />
        </el-carousel-item>
      </el-carousel>
      <!-- 游戏产品部分 -->
      <div class="gameProduct" v-loading="productLoading">
        <h3 style="margin: 0 0 0 10px">
          游戏产品
          <span style="float: right; font-weight: 300; margin: 0 10px 0 0"
            >更多>></span
          >
        </h3>
        <div style="border-bottom: 1px solid #dcdfe6; margin: 10px 0 0 0" />
        <el-row :gutter="20">
          <el-col
            :xs="24"
            :lg="6"
            :offset="0"
            v-for="item in nav"
            :key="item.id"
            style="padding: 20px"
          >
            <a
              :href="item.jump_url"
              target="_self"
              style="text-decoration: none; color: inherit; cursor: auto"
            >
              <el-row gutter="40">
                <el-col span="4">
                  <img
                    :src="item.image_url"
                    alt="image"
                    style="width: 40px; height: 40px; border-radius: 100px"
                  />
                </el-col>
                <el-col span="20">
                  <span style="font-weight: bolder">{{ item.title }}</span>
                  <i
                    class="fa fa-angle-right"
                    style="
                      float: right;
                      color: #abb0bd;
                      border: 1px #abb0bd solid;
                      padding: 0px 5px;
                      border-radius: 100px;
                    "
                  ></i>
                  <p style="font-size: 12px; color: #abb0bd; margin: 5px 0px">
                    {{ item.description }}
                  </p>
                </el-col>
              </el-row>
            </a>
          </el-col>
        </el-row>
      </div>
      <!-- 海外服务器部分 -->
      <div class="server" v-loading="serverLoading">
        <h3 style="margin: 0 0 0 10px">
          海外服务器
          <span style="float: right; font-weight: 300; margin: 0 10px 0 0"
            >更多>></span
          >
        </h3>
        <div style="border-bottom: 1px solid #dcdfe6; margin: 10px 0 0 0" />
        <el-row :gutter="20">
          <el-col
            :xs="24"
            :lg="6"
            :offset="0"
            v-for="item in server"
            :key="item.id"
            style="padding: 20px"
          >
            <!-- <a
              :href="item.url"
              target="_blank"
              style="text-decoration: none; color: inherit; cursor: auto"
            > -->
            <el-row gutter="40">
              <el-col span="6">
                <img
                  :src="item.image_url"
                  alt="image"
                  style="width: 60px; height: 40px; border-radius: 5px"
                />
              </el-col>
              <el-col span="18">
                <p
                  style="
                    font-weight: bolder;
                    margin-top: 0px;
                    margin-bottom: 0px;
                  "
                >
                  {{ item.title }}
                </p>
                <i
                  class="fa fa-angle-right"
                  style="
                    float: right;
                    color: #abb0bd;
                    border: 1px #abb0bd solid;
                    padding: 0px 5px;
                    border-radius: 100px;
                  "
                ></i>
                <span
                  class="buyBtn"
                  @click="$router.push('/server/' + item.id)"
                >
                  立即选购
                </span>
              </el-col>
            </el-row>
            <!-- </a> -->
          </el-col>
        </el-row>
      </div>
      <!-- 玩转棋牌部分 -->
      <div class="play" v-loading="articleLoading">
        <h3 style="margin: 0 0 0 10px">
          玩转棋牌
          <span style="float: right; font-weight: 300; margin: 0 10px 0 0"
            >更多>></span
          >
        </h3>
        <div style="border-bottom: 1px solid #dcdfe6; margin: 10px 0 0 0" />
        <el-row :gutter="20">
          <el-col
            :xs="24"
            :lg="6"
            :offset="0"
            v-for="item in tec"
            :key="item.id"
          >
            <!-- <a
              :href="item.url"
              target="_blank"
              style="text-decoration: none; color: inherit; cursor: auto"
            > -->
            <div class="card" @click="$router.push('/news/' + item.id)">
              <img
                :src="item.cover_image"
                alt="image"
                style="width: 100%; height: 150px; border-radius: 5px 5px 0 0"
              />
              <div style="padding: 10px 20px; height: 70px">
                <span>{{ item.title }}</span>
              </div>
            </div>
            <!-- </a> -->
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getArticles,
  getBanner,
  getProduct,
  getServerCategoryList,
} from "@/api/api";

export default {
  data() {
    return {
      swipe: [],
      nav: [],
      tec: [],
      server: [],
      swipeHeight: "400px",
      screenWidth: window.innerWidth,
      bannerLoading: false,
      serverLoading: false,
      productLoading: false,
      articleLoading: false,
    };
  },
  methods: {
    getProducts() {
      this.productLoading = true;
      getProduct().then((res) => {
        if (res.data.code === 200) {
          this.nav = res.data.data;
          this.productLoading = false;
        } else {
          this.$message.error("获取产品列表失败" + res.data.msg);
          this.productLoading = false;
        }
      });
    },
    getServerCategoryLists() {
      this.serverLoading = true;
      getServerCategoryList().then((res) => {
        if (res.data.code === 200) {
          this.server = res.data.data;
          this.serverLoading = false;
        } else {
          this.$message.error("获取服务器分类失败！" + res.data.msg);
          this.serverLoading = false;
        }
      });
    },
    getBanners() {
      this.bannerLoading = true;
      getBanner().then((res) => {
        if (res.data.code === 200) {
          this.swipe = res.data.data;
          this.bannerLoading = false;
        } else {
          this.$message.error(res.data.msg);
          this.bannerLoading = false;
        }
      });
    },
    getArticle() {
      this.articleLoading = true;
      getArticles()
        .then((res) => {
          if (res.data.code === 200) {
            this.tec = res.data.data;
            this.articleLoading = false;
          } else {
            this.$message.error(res.data.msg);
            this.articleLoading = false;
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    screeneeWidth() {
      if (this.screenWidth > 768) {
        this.swipeHeight = "400px";
      } else {
        this.swipeHeight = "200px";
      }
    },
  },
  created() {
    this.getArticle();
    this.screeneeWidth();
    this.getBanners();
    this.getServerCategoryLists();
    this.getProducts();
  },
};
</script>

<style>
body {
  margin: 0;
  background-color: #f1f5f8;
  overflow-x: hidden; /* 防止横向滚动条 */
}

.el-menu.el-menu--horizontal {
  border-bottom: solid 0px #e6e6e6 !important;
}

* {
  box-sizing: border-box;
}

.card {
  box-shadow: 0px 1px 6px 0px rgba(65, 106, 123, 0.1);
  border-radius: 5px;
  margin-top: 10px;
  transition: all 0.3s ease;
  border-radius: 5px;
}

.card:hover {
  border-radius: 5px;
  box-shadow: 0px 15px 28px 0px rgba(65, 106, 123, 0.2);
}

.banner_swipe {
  margin: 10px 150px 0 150px;
}

.gameProduct {
  background-color: white;
  margin: 20px 150px 0 150px;
  padding: 15px;
  color: #666666;
}

.server {
  background-color: white;
  margin: 20px 150px 0 150px;
  padding: 15px;
  color: #666666;
}

.play {
  background-color: white;
  margin: 20px 150px 0 150px;
  padding: 15px;
  color: #666666;
}

.buyBtn {
  font-size: 12px;
  color: #abb0bd;
  margin: 5px 0px;
  font-weight: bolder;
  border: 1px #abb0bd solid;
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.buyBtn:hover {
  background-color: #abb0bd;
  color: white;
}

/* @media screen and (min-width: 1920px) {
  .gameProduct {
    margin: 0 350px;
  }
} */

@media screen and (max-width: 768px) {
  .banner_swipe {
    margin: 0;
  }
  .gameProduct {
    margin: 0;
  }
  .server {
    margin: 0;
  }
  .play {
    margin: 0;
  }
}
</style>
