<template>
  <div>
    <div style="display: flex">
      <!-- 左侧菜单 -->
      <div
        class="left"
        :style="{
          width: isCollapse ? '64px' : '250px',
          transition: 'all 0.3s ease',
          height: '100vh',
        }"
      >
        <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          :collapse="isCollapse"
          style="height: 100%"
        >
          <div v-if="isCollapse === false" style="padding: 25px">
            <img src="@/assets/logo1.png" alt="logo" style="width: 100%" />
          </div>
          <div v-else style="padding: 10px">
            <img src="@/assets/logo3.png" alt="" style="width: 100%" />
          </div>
          <el-menu-item @click="isCollapse = !isCollapse">
            <i class="el-icon-more"></i>
            <span v-if="isCollapse === true" slot="title">展开</span>
            <span v-else slot="title">收起</span>
          </el-menu-item>
          <el-menu-item index="1" @click="open(1)">
            <i class="el-icon-menu"></i>
            <span slot="title">控制台</span>
          </el-menu-item>
          <el-menu-item index="2" @click="open(2)">
            <i class="el-icon-s-custom"></i>
            <span slot="title">下级客户</span>
          </el-menu-item>
          <el-menu-item index="3" @click="open(3)">
            <i class="el-icon-s-order"></i>
            <span slot="title">我的佣金</span>
          </el-menu-item>
          <el-menu-item index="4" @click="open(4)">
            <i class="el-icon-s-open"></i>
            <span slot="title">定制服务</span>
          </el-menu-item>
        </el-menu>
      </div>
      <!-- 右侧内容显示 -->
      <div class="right" style="flex: 1; width: 100vh; background-color: white">
        <div style="text-align: right">
          <el-popover placement="top-start" trigger="hover">
            <div>
              <p style="font-size: 16px; margin: 0">
                当前登录用户:<em
                  ><b>{{ username }} </b></em
                >
              </p>
              <p style="font-size: 16px; margin: 0">
                对应用户id:<em
                  ><b>{{ uid }} </b></em
                >
              </p>
              <p style="font-size: 16px; margin: 0">
                代理等级:
                <span
                  v-if="level === '1'"
                  style="font-weight: bolder; font-style: italic"
                  >银牌代理</span
                >
                <span
                  v-if="level === '2'"
                  style="font-weight: bolder; font-style: italic"
                  >金牌代理</span
                >
                <span
                  v-if="level === '3'"
                  style="font-weight: bolder; font-style: italic"
                  >白金代理</span
                >
                <span
                  v-if="level === '4'"
                  style="font-weight: bolder; font-style: italic"
                  >钻石代理</span
                >
              </p>
              <p style="font-size: 16px; margin: 0">帐户余额：{{ amount }}</p>
              <br />
              <el-button type="info" style="width: 100%" @click="startTour()"
                >引导程序</el-button
              >
              <br />
              <el-button
                type="danger"
                style="width: 100%; margin-top: 10px"
                @click="dialogVisible = true"
                >修改密码</el-button
              >
              <br />
              <el-button
                type="warning"
                style="width: 100%; margin-top: 10px"
                @click="openUpdateContactDialog()"
                >修改联系方式</el-button
              >
              <br />
              <el-button
                type="primary"
                style="width: 100%; margin-top: 10px"
                @click="open(5)"
                >绑定提现方式</el-button
              >
              <br />
              <el-button
                type="success"
                style="width: 100%; margin-top: 10px"
                @click="LogOuts()"
                >退出登录</el-button
              >
            </div>
            <el-image
              class="agent-avatar"
              :src="avatar"
              style="width: 70px; height: 70px; border-radius: 50px"
              slot="reference"
              @error="avatarError"
            ></el-image>
          </el-popover>
        </div>
        <el-tabs
          v-if="isShow == true"
          v-model="editableTabsValue"
          type="card"
          editable
          @edit="handleTabsEdit"
        >
          <el-tab-pane
            :key="item.name"
            v-for="item in editableTabs"
            :label="item.title"
            :name="item.name"
          >
            <component class="agentMain" :is="item.component"></component>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 修改密码弹窗 -->
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item label="请输入旧密码"
          ><el-input v-model="oldPassword"></el-input
        ></el-form-item>
        <el-form-item label="请输入新密码"
          ><el-input v-model="newPassword"></el-input
        ></el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="resetPwds()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加联系方式弹窗 -->
    <el-dialog
      title="添加联系方式"
      :visible.sync="addContactDialog"
      width="30%"
      center
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="您的QQ号：">
          <el-input v-model="addContact.qq"></el-input>
        </el-form-item>
        <el-form-item label="您的Telegram账号：">
          <el-input v-model="addContact.telegram"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button type="primary" @click="updateContacts()">确 定</button>
      </span>
    </el-dialog>
    <!-- 修改联系方式弹窗 -->
    <el-dialog
      title="修改联系方式"
      :visible.sync="editContactDialog"
      width="30%"
      center
    >
      <el-form>
        <el-form-item label="您的QQ号：">
          <el-input v-model="editQq"></el-input>
        </el-form-item>
        <el-form-item label="您的Telegram账号：">
          <el-input v-model="editTelegram"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button type="primary" @click="editContactDialog = false">取 消</button>
        <button type="primary" @click="updateContactss()">确 定</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DashBoard from "@/components/Agent/DashBoard.vue";
import MemberVue from "@/components/Agent/MemberVue.vue";
import CommVue from "@/components/Agent/CommVue.vue";
import CustomVue from "@/components/Agent/CustomVue.vue";
import DrawVue from "@/components/Agent/DrawVue.vue";
import { getAgentUSDT, getContact, resetPwd, updateContact } from "@/api/api";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

export default {
  data() {
    return {
      isCollapse: false,
      username: "",
      nickname: "",
      uid: "",
      avatar: "",
      level: "",
      oldPassword: "",
      newPassword: "",
      editableTabs: [], // 初始化为空数组
      editableTabsValue: "1",
      isShow: true,
      dialogVisible: false,
      componentsList: {
        1: "DashBoard",
        2: "MemberVue",
        3: "CommVue",
        4: "CustomVue",
        5: "DrawVue",
      },
      currentView: "DashBoard",
      amount: "",
      telegram: "",
      qq: "",
      editContact: [],
      addContact: [],
      addContactDialog: false,
      editContactDialog: false,
      editTelegram: "",
      editQq: "",
    };
  },
  methods: {
    // 步骤引导
    startTour() {
      const driverObj = driver({
        showProgress: true,
        nextBtnText: "下一步", // 修改 "Next" 按钮文字
        prevBtnText: "上一步", // 修改 "Previous" 按钮文字
        doneBtnText: "完成", // 修改 "Done" 按钮文字
        closeBtnText: "关闭", // 修改 "Close" 按钮文字
        steps: [
          {
            element: "all",
            popover: {
              title: "欢迎使用极速棋牌代理后台",
              description: "本界面将引导您正确使用本后台",
              side: "top",
            },
          },
          {
            element: ".agent-avatar",
            popover: {
              title: "头像显示区域",
              description: "鼠标移入此区域可以显示当前代理的相关信息数据",
              side: "top",
            },
          },
          {
            element: ".right",
            popover: {
              title: "内容显示区域",
              description:
                "此区域显示主要内容，进入页面默认显示为控制台，控制台会清晰明了的显示当前代理数据",
              side: "top",
            },
          },
          {
            element: ".left",
            popover: {
              title: "菜单选择",
              description:
                "在此处可以选择您需要操作的板块，点击后对应内容将在右侧显示",
              side: "top",
            },
          },
          {
            element: "all",
            popover: {
              title: "本次引导结束",
              description:
                "如果您需要再次查看，请通过右上角菜单来重新打开引导程序",
              side: "top",
            },
          },
        ],
      });
      driverObj.drive();
    },
    openUpdateContactDialog() {
      this.editContactDialog = true;
      this.editTelegram = this.telegram;
      this.editQq = this.qq;
    },
    updateContacts() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      updateContact(
        this.uid,
        this.addContact.telegram,
        this.addContact.qq
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功");
          this.addContactDialog = false;
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    updateContactss() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      updateContact(this.uid, this.editTelegram, this.editQq).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功");
          this.editContactDialog = false;
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    resetPwds() {
      const loading = this.$loading({
        lock: true,
        text: "正在全力加载中",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      const username = localStorage.getItem("username");
      resetPwd(token, username, this.oldPassword, this.newPassword).then(
        (res) => {
          if (res.data.code === 200) {
            this.dialogVisible = false;
            this.$message.success("修改密码成功！即将返回登录页面！");
            loading.close();
            setTimeout(() => {
              this.LogOuts();
            }, 2000);
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        }
      );
    },
    avatarError() {
      this.avatar = require("@/assets/defaultUser.jpg");
      console.log("触发加载失败，头像已经替换");
    },
    LogOuts() {
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("avatar");
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("nickname");
      localStorage.setItem("Agent", "false");
      localStorage.setItem("isLogin", "false");
      this.$message.success("退出登录成功，返回极速棋牌官网");
      this.$router.push("/");
      window.location.reload();
      // const token = localStorage.getItem("token");
      // LogOut(token).then((res) => {
      //   if (res.data.code === 1) {
      //     localStorage.removeItem("token");
      //     localStorage.removeItem("username");
      //     localStorage.removeItem("avatar");
      //     localStorage.removeItem("isLoggedIn");
      //     this.$message.success("退出登录成功");
      //     this.$router.push("/login");
      //   } else {
      //     this.$message.error(res.data.msg);
      //     localStorage.removeItem("token");
      //     localStorage.removeItem("username");
      //     localStorage.removeItem("avatar");
      //     localStorage.removeItem("isLoggedIn");
      //     this.$message.success("退出登录成功");
      //     this.$router.push("/login");
      //   }
      // });
    },
    open(code) {
      let existingTab = this.editableTabs.find(
        (tab) => tab.name === this.componentsList[code]
      );
      if (!existingTab) {
        this.currentView = this.componentsList[code];
        let menuName = {
          1: "控制台",
          2: "下级客户",
          3: "我的佣金",
          4: "定制服务",
          5: "绑定提现方式",
        };
        this.editableTabs.push({
          title: menuName[code],
          name: this.componentsList[code],
          component: this.componentsList[code],
        });
        this.editableTabsValue = this.componentsList[code];
      } else {
        this.editableTabsValue = existingTab.name;
      }
    },
    handleTabsEdit(targetName, action) {
      if (action === "add") {
        let newTabName = ++this.editableTabs.length;
        this.editableTabs.push({
          title: "New Tab", // 这里可以根据需要设置新的标签页标题
          name: newTabName,
          component: "NewTabComponent", // 你需要替换为实际的组件名称
        });
        this.editableTabsValue = newTabName;
      }
      if (action === "remove") {
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }
        this.editableTabsValue = activeName;
        this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
      }
    },
    getAgentUSDTs() {
      getAgentUSDT(this.uid).then((res) => {
        if (res.data.code === 200) {
          this.amount = res.data.data;
        } else {
          this.$message.error("余额获取失败");
        }
      });
    },
    getContacts() {
      getContact(this.uid).then((res) => {
        if (res.data.code === 200) {
          this.telegram = res.data.data.telegram;
          this.qq = res.data.data.qq;
          console.log(this.telegram, this.qq);
          this.checkContact();
        } else {
          // this.$message.error("联系方式获取失败！");
        }
      });
    },
    checkContact() {
      if (this.telegram === "" || this.qq === "") {
        console.log(111 + this.telegram, this.qq);
        this.$message.error("请完善联系方式！");
        this.addContactDialog = true;
      }
    },
  },
  created() {
    this.open(1);
    this.username = localStorage.getItem("username");
    this.uid = localStorage.getItem("uid");
    if (this.uid) {
      this.getContacts();
    }
    this.level = localStorage.getItem("level");
    this.getAgentUSDTs();
    // 传递信息给父组件阻止渲染其他元素
    this.$emit("show", "123123213");
  },
  mounted() {
    const tourCount = localStorage.getItem("tourCount");
    if (!tourCount) {
      this.startTour();
      localStorage.setItem("tourCount", 1);
    }
  },
  components: {
    DashBoard,
    MemberVue,
    CommVue,
    CustomVue,
    DrawVue,
  },
};
</script>

<style>
.guide-container {
  max-width: 600px;
  padding: 40px;
}

.title-major {
  color: var(--td-text-color-primary);
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
}

.title-sub {
  margin-top: 8px;
  color: var(--td-text-color-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.field {
  margin-top: 50px;
}

.label {
  margin-bottom: 8px;
  color: var(--td-text-color-primary);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.action {
  display: inline-flex;
  margin-top: 50px;
}

.action button:first-child {
  margin-right: 10px;
}
</style>
