<template>
  <div>
    <!-- 上层 -->
    <div style="margin: 0 0 10px 10px">
      <el-select
        v-model="value"
        placeholder="请选择定制服务"
        @change="chooseService()"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <!-- 主体表格显示 -->
    <div>
      <!-- 游戏换皮表格 -->
      <el-table
        v-if="value === '游戏换皮服务'"
        :data="change"
        style="width: 100%"
        border
        stripe
      >
        <el-table-column prop="id" label="ID" width="40"> </el-table-column>
        <el-table-column prop="uid" label="用户ID" width="70">
        </el-table-column>
        <el-table-column prop="pid" label="上级ID" width="70">
        </el-table-column>
        <el-table-column prop="product" label="产品名称"> </el-table-column>
        <el-table-column prop="skin_type" label="换皮方式">
          <template slot-scope="scope">
            <span v-if="scope.row.skin_type === '1'">全新设计</span>
            <span v-if="scope.row.skin_type === '2'">模仿产品</span>
          </template>
        </el-table-column>
        <el-table-column prop="contact" label="联系方式"> </el-table-column>
        <el-table-column prop="description" label="要求描述"> </el-table-column>
        <el-table-column prop="attachment" label="附件">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="点击查看大图"
              placement="top"
            >
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.attachment"
                @click="checkImg(scope.row.attachment)"
              ></el-image>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="createtime" label="创建时间">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime">{{
              formatDate(scope.row.createtime)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updatetime" label="更新时间">
          <template slot-scope="scope">
            <span v-if="scope.row.updatetime">{{
              formatDate(scope.row.updatetime)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="代理报价">
          <template slot-scope="scope">
            <span v-if="scope.row.price">{{ scope.row.price }}</span>
            <span v-else>未提供</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="openPrice(scope.row.id)"
              >编辑报价</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 二次开发表格 -->
      <el-table
        v-if="value === '二次开发服务'"
        :data="second"
        style="width: 100%"
        border
        stripe
      >
        <el-table-column prop="id" label="ID" width="40"> </el-table-column>
        <el-table-column prop="uid" label="用户ID" width="70">
        </el-table-column>
        <el-table-column prop="pid" label="上级ID" width="70">
        </el-table-column>
        <el-table-column prop="product" label="产品名称"> </el-table-column>
        <el-table-column prop="contact" label="联系方式"> </el-table-column>
        <el-table-column prop="description" label="要求描述"> </el-table-column>
        <el-table-column prop="attachment" label="附件">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="点击查看大图"
              placement="top"
            >
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.attachment"
                @click="checkImg(scope.row.attachment)"
              ></el-image>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="createtime" label="创建时间">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime">{{
              formatDate(scope.row.createtime)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updatetime" label="更新时间">
          <template slot-scope="scope">
            <span v-if="scope.row.updatetime">{{
              formatDate(scope.row.updatetime)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="代理报价">
          <template slot-scope="scope">
            <span v-if="scope.row.price">{{ scope.row.price }}</span>
            <span v-else>未提供</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="openPrice(scope.row.id)"
              >编辑报价</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 外包维护表格 -->
      <el-table
        v-if="value === '外包维护服务'"
        :data="outService"
        style="width: 100%"
        border
        stripe
      >
        <el-table-column prop="id" label="ID" width="40"> </el-table-column>
        <el-table-column prop="uid" label="用户ID" width="70">
        </el-table-column>
        <el-table-column prop="pid" label="上级ID" width="70">
        </el-table-column>
        <el-table-column prop="product" label="产品名称"> </el-table-column>
        <el-table-column prop="required" label="技术要求"> </el-table-column>
        <el-table-column prop="contact" label="联系方式"> </el-table-column>
        <el-table-column prop="description" label="要求描述"> </el-table-column>
        <el-table-column prop="attachment" label="附件">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="点击查看大图"
              placement="top"
            >
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.attachment"
                @click="checkImg(scope.row.attachment)"
              ></el-image>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="createtime" label="创建时间">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime">{{
              formatDate(scope.row.createtime)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updatetime" label="更新时间">
          <template slot-scope="scope">
            <span v-if="scope.row.updatetime">{{
              formatDate(scope.row.updatetime)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="代理报价">
          <template slot-scope="scope">
            <span v-if="scope.row.price">{{ scope.row.price }}</span>
            <span v-else>未提供</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="openPrice(scope.row.id)"
              >编辑报价</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 查看大图dialog -->
    <el-dialog title="查看大图" :visible.sync="dialogVisible" width="50%">
      <img :src="img" alt="" style="width: 100%" />
    </el-dialog>
    <!-- 填写报价 -->
    <el-dialog title="提示" :visible.sync="priceDialog" width="30%">
      <el-input
        v-model="price"
        type="number"
        placeholder="请输入报价"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="priceDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitPrice()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  fillOutPrice,
  fillPrice,
  fillSecondPrice,
  getGameChange,
  getOutService,
  getSecondDev,
} from "@/api/api";
import moment from "moment";
export default {
  data() {
    return {
      options: [
        {
          value: "游戏换皮服务",
          label: "游戏换皮服务",
        },
        {
          value: "二次开发服务",
          label: "二次开发服务",
        },
        {
          value: "外包维护服务",
          label: "外包维护服务",
        },
      ],
      value: "游戏换皮服务",
      change: [],
      dialogVisible: false,
      img: "",
      priceDialog: false,
      id: "",
      price: "",
      second: [],
      outService: [],
    };
  },
  methods: {
    fillPrices() {
      const loading = this.$loading({
        lock: true,
        text: "正在处理",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      fillPrice(this.id, this.price).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("提交成功");
          this.getGameChanges();
          this.priceDialog = false;
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    openPrice(id) {
      this.price = "";
      this.id = id;
      this.priceDialog = true;
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    checkImg(img) {
      this.dialogVisible = true;
      this.img = img;
    },
    getGameChanges() {
      const uid = localStorage.getItem("uid");
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      getGameChange(uid).then((res) => {
        if (res.data.code === 200) {
          this.change = res.data.data;
          loading.close();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    getSecondDevs() {
      const uid = localStorage.getItem("uid");
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      getSecondDev(uid).then((res) => {
        if (res.data.code === 200) {
          this.second = res.data.data;
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    fillSecondPrices() {
      const loading = this.$loading({
        lock: true,
        text: "正在处理",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      fillSecondPrice(this.id, this.price).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("提交成功");
          this.getSecondDevs();
          this.priceDialog = false;
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    submitPrice() {
      if (this.value === "游戏换皮服务") {
        this.fillPrices();
      } else if (this.value === "二次开发服务") {
        this.fillSecondPrices();
      } else if (this.value === "外包维护服务") {
        this.fillOutPrices();
      }
    },
    chooseService() {
      console.log("触发：" + this.value);
      switch (this.value) {
        case "游戏换皮服务":
          this.getGameChanges();
          break;
        case "二次开发服务":
          this.getSecondDevs();
          break;
        case "外包维护服务":
          this.getOutServices();
          break;
      }
    },
    getOutServices() {
      const uid = localStorage.getItem("uid");
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      getOutService(uid).then((res) => {
        if (res.data.code === 200) {
          this.outService = res.data.data;
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    fillOutPrices() {
      const loading = this.$loading({
        lock: true,
        text: "正在处理",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      fillOutPrice(this.id, this.price).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("提交成功！");
          this.priceDialog = false;
          this.getOutServices();
          loading.close();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.getGameChanges();
  },
};
</script>

<style></style>
