<template>
  <div>
    <div class="yono-qa">
      <el-tabs tab-position="left" type="border-card">
        <el-tab-pane label="产品售前类">
          <p>
            <span class="question">Q</span>
            <span>产品支持试用吗？</span>
          </p>
          <p>
            <span class="answer">A</span>
            <span
              >由于技术成本较高，暂不支持给客户部署试用，可以使用在线演示体验游戏功能，演示版本和正式版本有一定差距，正式版本功能更强大。</span
            >
          </p>
          <p>
            <span class="question">Q</span>
            <span>产品价格多少？有优惠吗？</span>
          </p>
          <p>
            <span class="answer">A</span>
            <span
              >您可以选择自己想要的产品，然后根据您提交的需求获取报价，我们的商务/代理将会给您报价与您取得联系。平台会不定期开放一些优惠活动，敬请关注。</span
            >
          </p>
          <p>
            <span class="question">Q</span>
            <span>没有技术团队可以购买并投入使用吗？</span>
          </p>
          <p>
            <span class="answer">A</span>
            <span
              >当然可以，我们提供各种定制服务包括了游戏换皮服务，二次开发服务，外包维护包月维护，个性化定制服务，从多个维度解决您没有技术团队的烦恼。即使您没有技术团队，一样轻松可以按照您的想法做游戏。</span
            >
          </p>
          <p>
            <span class="question">Q</span>
            <span>我不懂运营？但是想要购买投入使用可以吗？</span>
          </p>
          <p>
            <span class="answer">A</span>
            <span
              >针对此类客户，我们也提供极速学院，您可以在我们极速学院看视频学习游戏运营，极速学院为我们平台的付费客户免费开放。</span
            >
          </p>
          <p>
            <span class="question">Q</span>
            <span>购买后需要多久可以交付？</span>
          </p>
          <p>
            <span class="answer">A</span>
            <span
              >购买后我们通常会第一时间在平台上给您分配技术部署到您的服务器，分配技术后通常将会在2-3小时内完成部署。如果您没有服务器也没关系，极速也提供海外服务器销售业务，您可以在我们平台购买高稳定高性价比的优质免备案服务器，助您游戏顺利出海。</span
            >
          </p>
          <p>
            <span class="question">Q</span>
            <span>我有技术团队？可以购买使用吗？</span>
          </p>
          <p>
            <span class="answer">A</span>
            <span
              >当然可以，有技术团队购买我们的产品我们服务也不会打折，帮助您部署，如果您这边有不懂的地方，我们也会安排技术远程协助。</span
            >
          </p>
        </el-tab-pane>
        <el-tab-pane label="产品售后类">
          <p>
            <span class="question">Q</span>
            <span>购买后有产品质保吗？</span>
          </p>
          <p>
            <span class="answer">A</span>
            <span
              >产品我们都是经过严格测试过的，无重大BUG和问题。购买后首月赠送一个月轻量级维护服务，小问题我们都会免费帮您改。</span
            >
          </p>
          <p>
            <span class="question">Q</span>
            <span>购买后会不会收了钱不管事？</span>
          </p>
          <p>
            <span class="answer">A</span>
            <span
              >请不要拿极速和市面上的小鱼小虾公司做对比，极速平台只打造品牌化棋牌平台，只做行业内顶尖的棋牌服务商。我们一直坚持给客户提供高质量的售后服务。</span
            >
          </p>
        </el-tab-pane>
        <el-tab-pane label="游戏运营类">
          <p>
            <span class="question">Q</span>
            <span>我不懂游戏运营，如何系统学习运营？</span>
          </p>
          <p>
            <span class="answer">A</span>
            <span
              >进入极速学院里面有学习路线，教程都会通俗易懂，即使您是小白也能看懂。</span
            >
          </p>
        </el-tab-pane>
        <el-tab-pane label="产品技术类">
          <p>
            <span class="question">Q</span>
            <span>游戏可以拆出来吗？</span>
          </p>
          <p>
            <span class="answer">A</span>
            <span
              >产品购买后，如果您自己有技术团队可以自己尝试拆，如果需要协助我们也可以提供协助。如果需要极速技术团队拆分，我们会收取一定服务费。</span
            >
          </p>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<style>
.yono-qa {
  margin: 10px 150px;
}

.question {
  background-color: #2ba245;
  color: white;
  padding: 0px 5px;
  border-radius: 2px;
  margin-right: 10px;
}

.answer {
  background-color: #2782d7;
  color: white;
  padding: 0px 5px;
  border-radius: 2px;
  margin-right: 10px;
}

p {
  margin: 10px;
}

@media screen and (max-width: 768px) {
  .yono-qa {
    margin: 10px 10px;
  }
}
</style>
